import React, { useState } from "react";
import { ButtonMenu } from "./ButtonMenu";
import { SetNameDialog } from "./SetNameDialog";
import styled from "@emotion/styled";
import { map } from "lodash";
import { ui } from "../constants";
import { PlaylistAdd } from "@mui/icons-material";
import { CommonIconButton, MenuItemButton } from "./Various";
import { useUserData } from "./UserDataProvider";
import { useSettings } from "./SettingsProvider";

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const TargetSetLabel = styled.span`
  font-weight: 700;
  font-style: italic;
`;

export const SetButtonMenu = ({ setName }) => {
  const {
    settings: { lang },
  } = useSettings();
  const { sets, setFunctions: setFunc } = useUserData();
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState(null);

  const targetSetNames = Object.keys(sets).filter((x) => x !== setName);
  var items = map(targetSetNames, (targetSetName) => (
    <MenuItemButton
      key={targetSetName}
      onMouseDown={(event) => {
        setFunc.addSetToSet(targetSetName, setName);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <div>
        <span>{ui.labels.addAllToSet[lang]}</span>
        <TargetSetLabel>{targetSetName}</TargetSetLabel>
      </div>
    </MenuItemButton>
  ));

  const setItem = (
    <MenuItemButton
      key={"set-remove-all"}
      // onMouseDown={event => { removeFromSet(setName, null, setName); setShow(false); event.stopPropagation(); }}>
      onMouseDown={(event) => {
        setFunc.removeAll(setName);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <span>{ui.labels.removeAllFromThis[lang]}</span>
    </MenuItemButton>
  );

  items = [setItem, ...items];

  const button = (
    <CommonIconButton
      tooltip={ui.tooltips.addToSet[lang]}
      ariaPressed={show}
      icon={<PlaylistAdd />}
      onMouseDown={(event) => {
        setShow(true);
        event.stopPropagation();
      }}
    />
  );

  return (
    <ButtonMenu button={button} show={show} onExit={() => setShow(false)}>
      <MenuItems>{items}</MenuItems>
      <SetNameDialog
        show={dialog !== null}
        value={dialog}
        alreadyExists={dialog !== setName && Object.keys(sets).includes(dialog)}
        onCancel={() => setDialog(null)}
        onSave={() => {
          setFunc.addToSet(dialog, []);
          setDialog(null);
        }}
      ></SetNameDialog>
    </ButtonMenu>
  );
};
