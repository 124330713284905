import React from "react";
import styled from "@emotion/styled";
import { Button } from "./Button";
import { ui, colors } from "../constants";
import { upperCaseFirst, checkMissing } from "../utils";
import { Clear, ExpandLess, ExpandMore } from "@mui/icons-material";
import { CommonIconButton } from "./Various";

const TSInfoFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const TSInfo = styled.div`
  flex-grow: 1;
  padding-left: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const OptionsButtons = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const TSKey = styled.span`
  font-weight: 700;
  font-size: 0.9em;
`;

const MetaItems = styled.div`
  margin-top: 0.3em;
`;

const TransformButtons = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 5px 5px 5px;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const TransformButton = styled(Button)`
  padding: 0.2em 0.4em 0.2em 0.4em;
  background-color: transparent;
  &:hover {
    background-color: ${colors.grey10};
  }
  &:active {
    background-color: ${colors.grey70};
  }
  &[aria-pressed="true"] {
    background-color: ${colors.grey70};
  }
`;

const MetaKeyChunk = styled.span`
  margin-left: 1em;
  font-size: 1em;
  color: grey;
`;

const MetaCategoryValue = styled.span`
  margin-left: 0.3em;
  font-size: 1em;
`;

const MetaCategory = styled.span`
  font-weight: 400;
  font-size: 1em;
`;

const MetaItem = styled.div`
  display: block;
  padding: 0.08em 0 0.08em 0;
`;

export const createPlotTSContentRenderer =
  (unselectResult, lang, showKeychunks, toggleTransform) =>
  (key, content, selected, expanded, hover, toggleExpansion) => {
    var cats = expanded
      ? content.meta
      : content.meta.filter((x) => x.filter === 0 && x.default === 0);
    cats = cats.filter(meta => meta.category !== "URL");
    const metas = cats.map((meta) => (
      <MetaItem key={`${meta.category}-${meta.id}`}>
        <MetaCategory>{upperCaseFirst(meta.category) + ":"}</MetaCategory>
        <MetaCategoryValue>{upperCaseFirst(meta.value)}</MetaCategoryValue>
        {((meta.keyChunk !== "" && showKeychunks) ||
          checkMissing(meta.value, lang)) && (
          <MetaKeyChunk>{"[" + meta.keyChunk + "]"}</MetaKeyChunk>
        )}
      </MetaItem>
    ));

    const transformButtons = ["limit", "fit", "diff", "log"].map((trfm) => (
      <TransformButton
        key={trfm}
        tooltip={ui.tooltips.plotTransform[trfm][lang]}
        log={"results_preview_transform_" + trfm}
        ariaPressed={content.transform[trfm]}
        onMouseDown={(event) => {
          toggleTransform(key, trfm);
          event.stopPropagation();
        }}
      >
        {trfm.toUpperCase()}
      </TransformButton>
    ));

    return (
      <>
        <TSInfo>
          <TSInfoFlex>
            <TSKey>{content.key}</TSKey>
            <MetaItems>{metas}</MetaItems>
            <FlexGrow />
          </TSInfoFlex>
        </TSInfo>
        <TransformButtons>{transformButtons}</TransformButtons>
        <OptionsButtons>
          <CommonIconButton
            tooltip={ui.tooltips.removeFromPlot[lang]}
            icon={<Clear />}
            onMouseDown={(event) => {
              unselectResult(key);
              event.stopPropagation();
            }}
          />
          <FlexGrow />
          <CommonIconButton
            tooltip={
              expanded
                ? ui.tooltips.reduceMeta[lang]
                : ui.tooltips.expandMeta[lang]
            }
            icon={expanded ? <ExpandLess /> : <ExpandMore />}
            onMouseDown={(event) => {
              toggleExpansion(key);
              event.stopPropagation();
            }}
          />
        </OptionsButtons>
      </>
    );
  };
