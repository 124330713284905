import React, { memo } from "react";
import { Button } from "./Button";
import { map, flatten } from "lodash";
import { markThousands, upperCaseFirst, checkMissing } from "../utils";
import Highlighter from "react-highlight-words";
import { reduce, isEqual } from "lodash";
import { ui, colors } from "../constants";
import styled from "@emotion/styled/macro";
import {
  ExpandLess,
  ExpandMore,
  NavigateNext,
  Done,
  Clear
} from "@mui/icons-material";
import { css } from "@emotion/react";
import { useSettings } from "./SettingsProvider";

const ApplyFilterButton = styled(Button)`
  font-weight: 400;
  background-color: ${ui.stdColor80};
  color: white;
  padding: 0px 20px 0px 20px;
  border-left: 2px solid ${ui.button.backgroundColor};
  &:hover {
    background-color: ${ui.stdColor60};
  }
  &:active {
    background-color: ${ui.stdColor50};  
  }
`;

const SelectionCounter = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  background-color: ${ui.stdColor80};
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-left: 2px solid ${ui.button.backgroundColor};
`;

const ItemButton = styled(Button)`
  border-bottom: ${(props) => props.borderBottom};
  background-color: white;
  position: relative;
  border-left: ${(props) => (props.selectable ? "2px solid white" : "none")};

  &:hover {
    background-color: ${(props) =>
      props.selected ? ui.stdColor10 : colors.grey4};
    border-left-color: ${(props) => (props.selected ? "black" : colors.grey4)};
  }

  &:active {
    background-color: ${colors.grey10};
  }

  &:last-child {
    border-bottom: none;
  }

  ${(props) =>
    props.selected &&
    css`
      border-left-color: black;
      background-color: ${ui.stdColor10};
    `}
`;

const CategoryItem = styled.div`
  width: 100%;
  display: block;
  text-align: left;
`;

const KeyChunkHighlighter = styled(Highlighter)`
  font-size: 1em;
  margin-left: 1em;
  color: grey;
`;

const RelatedCategories = styled.div`
  font-weight: 400;
  font-size: 1em;
  margin-top: 0.25em;
  color: grey;
`;

const ValueCountLabel = styled.span`
  float: right;
  font-size: 1em;
  color: grey;
`;

const ValueHighlighter = styled(Highlighter)`
  font-size: 1em;
  margin-left: 1em;
`;

const RelatedCategory = styled.div`
  display: inline-flex;
  align-items: center;
`;

const CategoryItemShowCount = styled.div`
  font-weight: 400;
`;

const CategoryBar = styled.div`
  display: flex;
  top: 0px;
  position: sticky;
  height: 30px;
  z-index: 1;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const ExpandCategoryButton = styled(Button)`
  flex-grow: 1;
  ${(props) =>
    props.focus &&
    css`
      border-left: 2px solid black;
      background-color: ${ui.stdColor40};
    `}
`;

const CommonTitle = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

export const CategoriesView = memo(
  ({
    suggestions,
    categoriesExpanded,
    onExpandCategory,
    onReduceCategory,
    addSelection,
    removeSelection,
    setSelectedItems,
    onSelectCategoryItem,
    searchMode,
    expansion,
    selectedItems,
    ...other
  }) => {
    const searchTerms = [];

    const { settings } = useSettings();

    var itemCount = 0;
    var items = map(suggestions, (suggestion, j) => {
      var category = suggestion.category;
      let selectedCategory =
        selectedItems.length !== 0 && category === selectedItems[0].category;
      let selectedCount = selectedCategory ? selectedItems.length : 0;
      var expanded = categoriesExpanded.includes(category);
      const categoryItem = 
        <CategoryBar>
          <ExpandCategoryButton
            log="categories_expand_category"
            icon={expanded ? <ExpandLess /> : <ExpandMore />}
            iconPosition="right"
            onMouseDown={
              expanded
                ? (event) => {
                    onReduceCategory(category);
                    event.preventDefault();
                  }
                : (event) => {
                    onExpandCategory(category);
                    event.preventDefault();
                  }
            }
          >
            <CommonTitle>
              <Highlighter
                textToHighlight={upperCaseFirst(suggestion.category)}
                searchWords={searchTerms}
                highlightStyle={ui.searchHighlightStyle}
              />
              <FlexGrow />
              <CategoryItemShowCount>
                {suggestion.total}
              </CategoryItemShowCount>
            </CommonTitle>
          </ExpandCategoryButton>
          {settings.multiSelect && selectedCount > 0 && 
            <>
              <ApplyFilterButton
                icon={<Clear/>}
                onMouseDown={(event) => {
                  setSelectedItems([]);
                  event.preventDefault();
                  event.stopPropagation();
                }}/>
              <ApplyFilterButton
                icon={<Done />}
                onMouseDown={(event) => {
                  onSelectCategoryItem(selectedItems);
                  setSelectedItems([]);
                  event.preventDefault();
                  event.stopPropagation();
                }}>
              </ApplyFilterButton>
              <SelectionCounter>{selectedCount}</SelectionCounter>
            </>}
        </CategoryBar>;

      if (!expanded) {
        return [categoryItem];
      }

      itemCount++;
      var resultItems = [];
      const categoryItemCount = suggestion.results.length;
      for (let i = 0; i < categoryItemCount; i++) {
        let elem = suggestion.results[i];
        let selectedCategory =
          selectedItems.length !== 0 && category === selectedItems[0].category;
        let selected =
          selectedCategory && selectedItems.find((x) => x.id === elem.id);
        const related = elem.relatedCategories.map(({ category, value }, j) => (
          <RelatedCategory key={category}>
            <span>{upperCaseFirst(category + " " + value)}</span>
            {j === elem.relatedCategories.length - 1 ? null : (
              <NavigateNext fontSize="small" />
            )}
          </RelatedCategory>
        ));

        const item =
          <ItemButton
            key={elem.id}
            borderBottom={
              i < categoryItemCount - 1 || !expanded
                ? `1px solid ${ui.dividerColor}`
                : "0px"
            }
            selected={selected}
            selectable={settings.multiSelect}
            onMouseDown={(event) => {
              settings.multiSelect
                ? selected
                  ? removeSelection(elem)
                  : addSelection(elem)
                : onSelectCategoryItem([elem]);
              event.preventDefault();
            }}
          >
            <CategoryItem>
              {searchMode === "key" ? (
                <div style={{ fontWeight: 400 }}>
                  <Highlighter
                    textToHighlight={"[" + elem.keyChunk + "]"}
                    searchWords={searchTerms}
                    highlightStyle={ui.searchHighlightStyle}
                  />
                  <ValueHighlighter
                    textToHighlight={upperCaseFirst(elem.value)}
                    searchWords={searchTerms}
                    highlightStyle={ui.searchHighlightStyle}
                  />
                  <ValueCountLabel>{markThousands(elem.count)}</ValueCountLabel>
                </div>
              ) : (
                <>
                  <div style={{ fontWeight: 400 }}>
                    <Highlighter
                      textToHighlight={upperCaseFirst(elem.value)}
                      searchWords={searchTerms}
                      highlightStyle={ui.searchHighlightStyle}
                    />
                    {elem.keyChunk !== "" &&
                      (settings.showKeychunks ||
                        checkMissing(elem.value, settings.lang) ||
                        reduce(
                          searchTerms,
                          (match, term) =>
                            match || elem.keyChunk.match(new RegExp(term, "i")),
                          false,
                        )) && (
                        <KeyChunkHighlighter
                          textToHighlight={"[" + elem.keyChunk + "]"}
                          searchWords={searchTerms}
                          highlightStyle={ui.searchHighlightStyle}
                        />
                      )}
                    <ValueCountLabel>
                      {markThousands(elem.count)}
                    </ValueCountLabel>
                  </div>
                  {related.length != 0 && (
                    <RelatedCategories>{related}</RelatedCategories>
                  )}
                </>
              )}
            </CategoryItem>
          </ItemButton>;

        itemCount++;
        resultItems.push(item);
      }

      return [categoryItem, ...resultItems];
    });

    items = flatten(items);
    return <>{items.length > 0 && items}</>;
  },
  (prevProps, nextProps) => {
    return (
      isEqual(nextProps.categoriesExpanded, prevProps.categoriesExpanded) &&
      isEqual(nextProps.suggestions, prevProps.suggestions) &&
      nextProps.keySearch === prevProps.keySearch &&
      nextProps.expansion === prevProps.expansion &&
      isEqual(nextProps.selectedItems, prevProps.selectedItems) &&
      nextProps.searchMode === prevProps.searchMode
    );
  },
);
