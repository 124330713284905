import styled from "@emotion/styled";
import {
  Box,
  CircularProgress,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import SearchIcon from "@mui/icons-material/Search";
import { formatDistanceToNow, formatISO9075 } from "date-fns";
import { enGB, de } from "date-fns/locale";
import React, { useCallback, useEffect, useState } from "react";
import { useSettings } from "./SettingsProvider";
import { useUser } from "./UserProvider";

const dateLocales = { en: enGB, de };

const ImportArticle = styled.article`
  flex: 1 0 100%;
  overflow: auto;
  user-select: text;
`;

const SortableTableHeader = ({ children, onSort, sortDirection = false }) => (
  <TableCell component="th" sortDirection={sortDirection}>
    <TableSortLabel
      active={!!sortDirection}
      direction={sortDirection ? sortDirection : undefined}
      onClick={onSort}
    >
      {children}
      <Box component="span" sx={visuallyHidden}>
        sorted ascending
      </Box>
    </TableSortLabel>
  </TableCell>
);

const TableToolbar = ({ setFilter }) => {
  return (
    <Toolbar
      sx={{
        padding: "1em 2em",
      }}
    >
      <Tooltip title="Filter list">
        <TextField
          label="Search"
          size="small"
          sx={{ width: "20%" }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onKeyUp={(event) => setFilter(event?.target?.value)}
        />
      </Tooltip>
    </Toolbar>
  );
};

export const ImportStatus = () => {
  const {
    settings: { lang },
  } = useSettings();
  const { auth, refreshToken, resetUser } = useUser();

  const [datasets, setDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortColumn, setSortColumn] = useState("lastUpdate");
  const [sortDirection, setSortDirection] = useState("initial");
  const setSorting = (column) => {
    if (sortColumn === column) {
      setSortDirection(sortDirection === "initial" ? "reverse" : "initial");
    } else {
      setSortDirection("initial");
      setSortColumn(column);
    }
  };
  const getColumnSortDirection = (column) => {
    if (sortColumn !== column) {
      return false;
    }
    return sortDirection === "initial" ? "asc" : "desc";
  };
  const sortByColumn = useCallback(
    (a, b) => {
      const [first, second] = sortDirection === "initial" ? [a, b] : [b, a];
      if (typeof first[sortColumn] === "string") {
        return first[sortColumn].localeCompare(second[sortColumn]);
      }
      return second[sortColumn] - first[sortColumn];
    },
    [sortColumn, sortDirection],
  );

  const [filter, setFilter] = useState("");
  const matchesSearch = (set) => {
    if (!filter) {
      return true;
    }
    const keyMatch = set.key.match(filter) || [];
    const descriptionMatch =
      (set.description || "").toLowerCase().match(filter) || [];
    return keyMatch.length > 0 || descriptionMatch.length > 0;
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/imports/status`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${auth.jwt}`,
            },
          },
        );
        if (response.status === 401) {
          resetUser();
          return;
        }

        refreshToken(response.headers.get("X-JWT"));
        const json = await response.json();
        setDatasets(json.datasets);
      } finally {
        setLoading(false);
      }
    })();
  }, [auth.username]);

  return (
    <ImportArticle>
      <h2>Import Status </h2>

      <TableContainer component={Paper}>
        <TableToolbar setFilter={setFilter} />
        <Table size="small" sx={{ maxHeight: "30vh" }}>
          <colgroup>
            <col width="20%" />
            <col width="70%" />
            <col width="10%" />
          </colgroup>
          <TableHead>
            <TableRow>
              <SortableTableHeader
                onSort={() => setSorting("key")}
                sortDirection={getColumnSortDirection("key")}
              >
                Dataset
              </SortableTableHeader>
              <TableCell component="th">Description</TableCell>
              <SortableTableHeader
                onSort={() => setSorting("lastUpdate")}
                sortDirection={getColumnSortDirection("lastUpdate")}
              >
                Last update
              </SortableTableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan="3" align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              datasets
                .filter(matchesSearch)
                .sort(sortByColumn)
                .map(({ key, description, lastUpdate }) => (
                  <TableRow key={key}>
                    <TableCell>{key}</TableCell>
                    <TableCell>{description}</TableCell>
                    <TableCell title={formatISO9075(lastUpdate)}>
                      {formatDistanceToNow(lastUpdate, {
                        locale: dateLocales[lang] ?? dateLocales.en,
                        addSuffix: true,
                      })}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </ImportArticle>
  );
};
