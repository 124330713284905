import React from 'react';
import styled from "@emotion/styled";

const StyledScrollBox = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
`

export const ScrollBox = ({
  children,
  scrollRef,
  onScrollEnd,
  ...other
}) => {
  
  const scroll = event => {
    var end = Math.abs(event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight) < 1
    if(end && onScrollEnd) {
      onScrollEnd()
    }
  }
    
  return(
    <StyledScrollBox onScroll={scroll} ref={scrollRef} {...other}>
      {children}
    </StyledScrollBox>)
}