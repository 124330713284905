import styled from "@emotion/styled";
import React from "react";
import { ui } from "../constants";

const TitleDiv = styled.div`
  display: flex;
  width: 40%;
`;

const TitleTSE = styled.div`
  padding: 0 0.5em 0 0.5em;
  display: flex;
  font-weight: 300;
  font-size: 1.5em;
  align-items: center;
  color: white;
  font-family: "Raleway", sans-serif;
  background-color: ${ui.stdColor60};
`;

const TitleSlash = styled.div`
  height: 0;
  width: 0;
  border-left: 10px solid ${ui.stdColor100};
  border-bottom: 40px solid transparent;
  border-top: 0px solid;
  border-right: 0px solid;
`;

const TitleKOF = styled.div`
  padding: 0 0.3em 0 0.35em;
  letter-spacing: 0.05em;
  display: flex;
  font-weight: 700;
  font-size: 2em;
  align-items: center;
  font-family: "Raleway", sans-serif;
  color: white;
  background-color: ${ui.stdColor100};
`;

export const Title = () => {
  return (
    <TitleDiv>
      <TitleKOF>KOF</TitleKOF>
      <TitleSlash />
      <TitleTSE>Time Series Explorer</TitleTSE>
    </TitleDiv>
  );
};
