import React, { forwardRef, memo } from "react";
import { Button } from "./Button";
import { upperCaseFirst, checkMissing } from "../utils";
import { ui, colors } from "../constants";
import { isEqual, map } from "lodash";
import styled from "@emotion/styled";
import { Clear, Add, AddSharp } from "@mui/icons-material";
import { useSettings } from "./SettingsProvider";

const CommonCount = styled.span`
  font-weight: 400;
`;

const ClearFiltersLabel = styled.div`
  text-align: left;
`;

const ClearFiltersButton = styled(Button)`
  justify-content: space-between;
  height: 30px;
`;

const FilterTerm = styled.span`
  font-weight: 400;
`;

const FilterTermDiv = styled.div`
  width: 100%;
  text-align: left;
`;

const FilterKeyChunk = styled.span`
  font-weight: 400;
  margin-left: 1em;
  color: grey;
`;

const FilterValue = styled.span`
  font-weight: 400;
  margin-left: 0.5em;
`;

const FilterCategory = styled.span`
  font-weight: 700;
`;

const CategoryFilterBar = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  border-bottom: 1px solid ${colors.grey20};
  &:last-child {
    border-bottom: none;
  }
`;

const FilterBlock = styled.div`
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 400;
`;

const FilterButtons = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

const FilterButton = styled(Button)`
  justify-content: space-between;
  background-color: white;
  /* border-bottom: ${(props) =>
    props.nodivider ? "none" : `1px solid ${colors.grey20}`}; */
  &:hover {
    background-color: ${colors.grey4};
  }
  &:active {
    background-color: ${colors.grey10};
  }
`;

const FilterButtonClear = styled(FilterButton)`
  /* flex-grow: 1; */
  flex: 1;
`;

const FilterButtonItem = styled(FilterButton)`
  border-bottom: 1px solid ${colors.grey20};
  /* margin-left: 1em; */
  &:last-child {
    border-bottom: none;
  }
`;

const CommonTitle = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const CommonItems = styled(FilterButtons)`
  margin-top: 0px;
`;

const ExpandCommonButton = styled(Button)`
  width: 100%;
  height: 30px;
  &:disabled {
    background-color: ${ui.button.backgroundColor};
    color: ${ui.button.color};
  }
`;

export const FilterView = memo(
  forwardRef(
    (
      {
        filters,
        common,
        defaultFilters,
        filterTerms,
        onFilterRemove,
        onFilterTermRemove,
        onClear,
        hasDefaults,
        keySearch,
        commonMetaExpanded,
        setCommonMetaExpanded,
        onSelectCategoryItem,
        setSelectedItems,
        ...other
      },
      ref,
    ) => {
      const { settings } = useSettings();

      const filterItems = map(filters, (elems, category) => {
        if (elems.length === 1) {
          let elem = elems[0];
          return (
            <CategoryFilterBar key={elem.id}>
              <FilterButtonClear
                // tooltip={ui.tooltips.removeFilter[settings.lang]}
                icon={<Clear />}
                iconPosition="right"
                onMouseDown={() => {
                  onFilterRemove(category);
                }}
              >
                <FilterBlock>
                  <FilterCategory>
                    {upperCaseFirst(elem.category)}
                  </FilterCategory>
                  <FilterValue>{upperCaseFirst(elem.value)}</FilterValue>
                  {elem.keyChunk !== "" &&
                    (settings.showKeychunks ||
                      checkMissing(elem.value, settings.lang)) && (
                      <FilterKeyChunk>
                        {"[" + elem.keyChunk + "]"}
                      </FilterKeyChunk>
                    )}
                </FilterBlock>
              </FilterButtonClear>
              {settings.multiSelect && (
                <FilterButton
                  tooltip={ui.tooltips.addCategoryFilter[settings.lang]}
                  icon={<AddSharp />}
                  padding="2px"
                  // iconChildrenSpace='-10px'
                  iconPosition="left"
                  onMouseDown={() => {
                    setSelectedItems(elems);
                    onFilterRemove(category, undefined, true);
                  }}
                />
              )}
            </CategoryFilterBar>
          );
        } else {
          return (
            <React.Fragment key={category}>
              <CategoryFilterBar>
                <FilterButtonClear
                  // tooltip={ui.tooltips.removeCategoryFilter[settings.lang]}
                  icon={<Clear />}
                  iconPosition="right"
                  onMouseDown={() => {
                    onFilterRemove(category);
                  }}
                >
                  <ClearFiltersLabel>
                    {upperCaseFirst(category)}
                  </ClearFiltersLabel>
                </FilterButtonClear>
                {settings.multiSelect && (
                  <FilterButton
                    tooltip={ui.tooltips.addCategoryFilter[settings.lang]}
                    icon={<AddSharp />}
                    padding="2px"
                    // iconChildrenSpace='-10px'
                    iconPosition="left"
                    onMouseDown={() => {
                      setSelectedItems(elems);
                      onFilterRemove(category, undefined, true);
                    }}
                  >
                    {/* <FilterTerm><i>Add</i></FilterTerm> */}
                  </FilterButton>
                )}
              </CategoryFilterBar>
              {elems.map((elem) => (
                <FilterButtonItem
                  key={`${category}-${elem.id}`}
                  // tooltip={ui.tooltips.removeFilter[settings.lang]}
                  icon={<Clear />}
                  iconPosition="right"
                  onMouseDown={() => onFilterRemove(category, elem)}
                >
                  <FilterBlock>
                    <span>{upperCaseFirst(elem.value)}</span>
                    {elem.keyChunk !== "" &&
                      (settings.showKeychunks ||
                        checkMissing(elem.value, settings.lang)) && (
                        <FilterKeyChunk>
                          {"[" + elem.keyChunk + "]"}
                        </FilterKeyChunk>
                      )}
                  </FilterBlock>
                </FilterButtonItem>
              ))}
            </React.Fragment>
          );
        }
      });

      // const userFilters = filters.filter(filter => !filter.auto)
      // const filterItems = userFilters.map((filter,j) => (
      //   <FilterButton
      //     tooltip={ui.tooltips.removeFilter[settings.lang]}
      //     last={j === userFilters.length-1}
      //     icon={<Clear/>}
      //     iconPosition='right'
      //     onMouseDown={filter.auto ? undefined : () => onFilterRemove(filter)}>
      //       <FilterBlock>
      //         <FilterCategory>{upperCaseFirst(filter.category)}</FilterCategory>
      //         <FilterValue>{upperCaseFirst(filter.value)}</FilterValue>
      //         {filter.keyChunk !== '' && (settings.showKeychunks || checkMissing(filter.value, settings.lang)) &&
      //         <FilterKeyChunk >{'['+filter.keyChunk+']'}</FilterKeyChunk>}
      //       </FilterBlock>
      //   </FilterButton>))

      const filterTermItems = filterTerms.map((term, j) => (
        <FilterButtonItem
          key={term}
          // tooltip={ui.tooltips.removeFilterTerm[settings.lang]}
          icon={<Clear />}
          iconPosition="right"
          onMouseDown={() => onFilterTermRemove(term)}
        >
          <FilterTermDiv>
            <FilterTerm>{term}</FilterTerm>
          </FilterTermDiv>
        </FilterButtonItem>
      ));

      const addDefault = (
        <FilterButtonItem
          // tooltip={ui.tooltips.addDefaultFilters[settings.lang]}
          icon={<Add />}
          log="add_default_filters"
          // iconChildrenSpace='-10px'
          iconPosition="right"
          onMouseDown={() => onSelectCategoryItem(defaultFilters)}
        >
          <FilterTermDiv>
            <FilterTerm>
              <i>{ui.labels.addDefaults[settings.lang]}</i>
            </FilterTerm>
          </FilterTermDiv>
        </FilterButtonItem>
      );

      const importantFilters = common.filter((filter) => !filter.default);
      const noExpansion = importantFilters.length === common.length;
      // const shownFilters = commonMetaExpanded ? common : importantFilters
      const shownFilters = importantFilters;
      const commonItems = shownFilters.map((filter) => (
        <FilterButtonItem
          key={`${filter.category}-${filter.id}`}
          // tooltip={ui.tooltips.selectCommonMeta[settings.lang]}
          onMouseDown={(event) => onSelectCategoryItem([filter])}
        >
          <FilterBlock>
            <FilterCategory>{upperCaseFirst(filter.category)}</FilterCategory>
            <FilterValue>{upperCaseFirst(filter.value)}</FilterValue>
            {filter.keyChunk !== "" &&
              (settings.showKeychunks ||
                checkMissing(filter.value, settings.lang)) && (
                <FilterKeyChunk>{"[" + filter.keyChunk + "]"}</FilterKeyChunk>
              )}
          </FilterBlock>
        </FilterButtonItem>
      ));

      return (
        <div ref={ref} {...other}>
          {(filterItems.length !== 0 || filterTermItems.length !== 0) && (
            <FilterButtons>
              <ClearFiltersButton
                // tooltip={ui.tooltips.clearFilters[settings.lang]}
                icon={<Clear />}
                iconPosition="right"
                onMouseDown={onClear}
              >
                <ClearFiltersLabel>
                  {ui.labels.filters[settings.lang]}
                </ClearFiltersLabel>
              </ClearFiltersButton>
              {filterTermItems}
              {filterItems}
              {defaultFilters.length !== 0 && addDefault}
            </FilterButtons>
          )}
          {commonItems.length !== 0 && (
            <CommonItems>
              <ExpandCommonButton
                // tooltip={noExpansion ? undefined : (commonMetaExpanded ? ui.tooltips.reduceCommonMeta[settings.lang] : ui.tooltips.expandCommonMeta[settings.lang])}
                // icon={noExpansion ? undefined : (commonMetaExpanded ? <ExpandLess/> : <ExpandMore/>)}
                // log='expand_common_meta'
                // iconPosition='right'
                disabled={true}
                onMouseDown={(event) =>
                  setCommonMetaExpanded(!commonMetaExpanded)
                }
              >
                <CommonTitle>
                  <span>{ui.labels.commonMeta[settings.lang]}</span>
                  {/* {!noExpansion && <CommonCount>{common.length}</CommonCount>} */}
                  {/* {!noExpansion && <CommonCount>{shownFilters.length}/{common.length}</CommonCount>} */}
                </CommonTitle>
              </ExpandCommonButton>
              {commonItems}
            </CommonItems>
          )}
        </div>
      );
    },
  ),
  (prevProps, nextProps) => {
    return (
      isEqual(prevProps.filterTerms, nextProps.filterTerms) &&
      isEqual(prevProps.filters, nextProps.filters) &&
      isEqual(prevProps.keySearch, nextProps.keySearch) &&
      isEqual(prevProps.style, nextProps.style) &&
      isEqual(prevProps.common, nextProps.common) &&
      isEqual(prevProps.commonMetaExpanded, nextProps.commonMetaExpanded)
    );
  },
);
