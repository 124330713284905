import React, { useState, useRef, useLayoutEffect } from "react";
import { Button } from "./Button";
import { ui } from "../constants";
import { map } from "lodash";
import { SetListItem } from "./SetListItem";
import { SetNameDialog } from "./SetNameDialog";
import styled from "@emotion/styled";
import { ViewTitle } from "./ViewTitle";
import { Add } from "@mui/icons-material";
import { useUser } from "./UserProvider";
import { useUserData } from "./UserDataProvider";
import { useSettings } from "./SettingsProvider";

const Main = styled.div`
  display: block;
  flex-direction: column;
  align-items: flex-start;
`;

const NewSetButton = styled(Button)`
  margin-top: 10px;
  float: right;
`;

const SetList = styled.div`
  overflow-y: auto;
  margin-top: 7px;
  max-height: ${(props) =>
    `calc(100% - ${props.topHeight}px - ${props.bottomHeight}px - 7px - 7px)`};
  display: flex;
  flex-direction: column;
`;

const DataWarning = styled.div`
  margin-top: 5px;
  margin-bottom: 3px;
  font-weight: 400;
`;

export const SetsView = () => {
  const { auth } = useUser();
  const isLoggedIn = auth.loggedIn;
  const {
    settings: { lang },
  } = useSettings();
  const { sets, activeSet, setFunctions } = useUserData();
  const [dialog, setDialog] = useState("");
  const [showDialog, setShowDialog] = useState(false);
  const [topHeight, setTopHeight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);

  const topRef = useRef();
  const bottomRef = useRef();

  useLayoutEffect(() => {
    const top = topRef.current;
    if (top && top.clientHeight !== topHeight) {
      setTopHeight(top.clientHeight);
    }
    const bottom = bottomRef.current;
    if (bottom && bottom.clientHeight !== bottomHeight) {
      setBottomHeight(bottom.clientHeight);
    }
  });

  const items = map(sets, (elems, setName) => (
    <SetListItem
      key={setName}
      setName={setName}
      selected={setName === activeSet}
    />
  ));

  return (
    <Main>
      <ViewTitle label={ui.labels.setsTitle[lang]} ref={topRef} />
      {!isLoggedIn && (
        <DataWarning>
          <i>{ui.labels.setsWarn[lang]}</i>
        </DataWarning>
      )}
      {items.length > 0 && (
        <SetList topHeight={topHeight} bottomHeight={bottomHeight}>
          {items}
        </SetList>
      )}
      <NewSetButton
        tooltip={ui.tooltips.newSet[lang]}
        ref={bottomRef}
        icon={<Add />}
        iconPosition="left"
        onMouseDown={(event) => {
          setShowDialog(true);
          event.preventDefault();
        }}
      >
        {ui.labels.newSet[lang]}
      </NewSetButton>
      <SetNameDialog
        show={showDialog}
        value={dialog}
        alreadyExists={Object.keys(sets).includes(dialog)}
        onCancel={() => {
          setDialog("");
          setShowDialog(false);
        }}
        onSave={() => {
          setFunctions.addToSet(dialog, []);
          setDialog("");
          setShowDialog(false);
        }}
        onChange={(value) => setDialog(value)}
      />
    </Main>
  );
};
