import React, { useRef, useState } from "react";
import { PreviewGraph } from "./PreviewGraph";
import { ScrollBox } from "./ScrollBox";
import { List } from "./List";
import { ViewTitle } from "./ViewTitle";
import { ui } from "../constants";
import styled from "@emotion/styled";

const ColumnContainer = styled.div`
  display: grid;
  grid-template-rows: auto 1fr 1fr;
`;

const DataWarning = styled.div`
  margin-bottom: 10px;
  margin-top: -2px;
  font-weight: 400;
`;
// const GraphBox = styled.div`
//   height: 55%;
//   display: flex;
//   flex-direction: column;
//   background-color: white;
//   box-shadow: ${ui.boxShadow};
// `

const TSListScrollBox = styled(ScrollBox)`
  margin-top: 15px;
  box-shadow: ${ui.boxShadow};
`;

const StyledPreviewGraph = styled(PreviewGraph)`
  margin-top: 7px;
`;

// const GraphDiv = styled.div`
//   height: calc(100% - 2em);
//   width: 100%;
//   padding: 5px 5px 5px 5px;
// `

// const TimePeriodButtons = styled.div`
//   display: flex;
//   margin: 3px 0 5px 0;
//   align-items: center;
//   justify-content: center;
// `

// const TimePeriodButton = styled(Button)`
//   padding: 0.2em 0.4em 0.2em 0.4em;
//   background-color: transparent;
//   &:hover {
//     background-color: ${colors.grey10};
//   }
//   &:active {
//     background-color: ${colors.grey70};
//   }
//   &[aria-pressed='true'] {
//     background-color: ${colors.grey70};
//   }
// `

export const PlotView = ({
  selectionKeys,
  selections,
  selectionColors,
  tsContentRenderer,
  lang,
  ...other
}) => {
  // const [hoverSelection, setHoverSelection] = useState(null)
  // const [timePeriod, setTimePeriod] = useState(undefined)
  const [dataWarning, setDataWarning] = useState(false);

  const titleRef = useRef(null);

  // const changeHover = (key, hover) => {
  //   if(hover) {
  //     setHoverSelection(key)
  //   } else {
  //     if(hoverSelection === key) {
  //       setHoverSelection(null)
  //     }
  //   }
  // }

  // const toggleTimePeriod = newTimePeriod => {
  //   if(newTimePeriod === timePeriod) {
  //     setTimePeriod(undefined)
  //   } else {
  //     setTimePeriod(newTimePeriod)
  //   }
  // }

  // const timePeriodButtons = ['last120','last60','last24'].map(tp => (
  //   <TimePeriodButton
  //     key={tp}
  //     tooltip={ui.tooltips.plotTransform[tp][lang]}
  //     ariaPressed={timePeriod===tp}
  //     onMouseDown={(event) => { toggleTimePeriod(tp); event.stopPropagation(); }}>
  //       {tp.toUpperCase()}
  //   </TimePeriodButton>
  // ))

  return (
    <ColumnContainer {...other}>
      <div>
        <ViewTitle
          ref={titleRef}
          label={ui.labels.plot[lang]}
          count={selections.length}
        />
        {selections.length > 0 && dataWarning && (
          <DataWarning>
            <i>{ui.labels.previewWarnLogin[lang]}</i>
          </DataWarning>
        )}
      </div>
      {selections.length !== 0 && (
        <>
          <StyledPreviewGraph
            keys={selectionKeys}
            transforms={selections.map((x) => x.transform)}
            // timePeriod={timePeriod}
            // hoverKey={hoverSelection !== null ? hoverSelection : undefined}
            colors={selectionColors}
            setDataWarning={setDataWarning}
            lang={lang}
          />
          {/* <GraphBox>
          <GraphDiv>
            <PreviewGraph
              keys={selectionKeys}
              transforms={selections.map(x => x.transform)}
              timePeriod={timePeriod}
              hoverKey={hoverSelection !== null ? hoverSelection : undefined}
              colors={selectionColors}
              lang={lang}
              />
          </GraphDiv>
          <TimePeriodButtons>
            {timePeriodButtons}
          </TimePeriodButtons>
        </GraphBox> */}
          <TSListScrollBox>
            <List
              itemKeys={selectionKeys}
              itemContents={selections}
              selectedItems={selectionKeys}
              selectionColors={selectionColors}
              dividerColor={ui.dividerColor}
              // onChangeHover={changeHover}
              itemContentRenderer={tsContentRenderer}
            />
          </TSListScrollBox>
        </>
      )}
    </ColumnContainer>
  );
};
