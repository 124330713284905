import React, { useState, useEffect, useRef } from "react";
// import { LineChart, XAxis, YAxis, Line, ResponsiveContainer, CartesianGrid, Tooltip, ReferenceArea } from 'recharts';
import { markThousands } from "../utils";
import { cloneDeep, findLastIndex, isEqual } from "lodash";
import styled from "@emotion/styled";
import { mean, std } from "mathjs";
import { ui } from "../constants";
import ReactECharts from "echarts-for-react";
import { usePrevious } from "./Various";
import { useUser } from "./UserProvider";

const StyledReactECharts = styled(ReactECharts)`
  padding-bottom: 5px;
  margin: 0px;
  background-color: white;
  min-height: 100%;
`;

const NoData = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30%;
  width: 100%;
`;

const DataWarning = styled.div`
  margin-bottom: 15px;
  font-weight: 400;
`;

export const PreviewGraph = ({
  keys,
  transforms,
  colors,
  hoverKey,
  lang,
  setDataWarning,
  ...other
}) => {
  const [transformedData, setTransformedData] = useState([]);
  const requestId = useRef(0);
  const dataRef = useRef([]);

  const prevTransforms = usePrevious(transforms);
  const prevKeys = usePrevious(keys);

  const { auth } = useUser();
  const jwt = auth.jwt;

  useEffect(() => {
    if (!isEqual(keys, prevKeys)) {
      fetchData();
    } else if (!isEqual(transforms, prevTransforms)) {
      transformData();
    }
  });

  useEffect(() => {
    fetchData();
  }, [jwt]);

  const fetchData = () => {
    if (keys.length === 0) {
      dataRef.current = [];
      setTransformedData([]);
      return;
    }

    const newRequestId = requestId.current + 1;
    requestId.current = newRequestId;

    const url = jwt
      ? `${process.env.REACT_APP_API_URL}/ts/main`
      : `${process.env.REACT_APP_API_URL}/ts/public`;

    fetch(url, {
      method: "POST",
      body: JSON.stringify({
        keys: keys,
        jwt: jwt,
      }),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => res.json())
      .then((json) => {
        if (requestId.current === newRequestId) {
          setDataWarning(json.message === "preview");
          loadData(json.csv);
        }
      })
      .catch(function (error) {
        dataRef.current = [];
        setTransformedData([]);
        setDataWarning(false);
        console.log("Request failed", error);
      });
  };
  //   var url = (jwt === null) ? `https://datenservice.kof.ethz.ch/api/v1/public/ts?mime=csv&keys=${keys.join()}` :
  //   `https://datenservice.kof.ethz.ch/api/v1/main/ts?mime=csv&keys=${keys.join()}`;

  //   var url = (jwt === null) ? `https://datenservice.kof.ethz.ch/api/v1/public/ts?mime=csv&keys=${keys.join()}` :
  //   `https://datenservice.kof.ethz.ch/api/v1/main/ts?mime=csv&keys=${keys.join()}`;

  //   fetch(url, {
  //     method: 'GET',
  //     headers: jwt === null ? {} : { Authorization: `Bearer ${jwt}` }
  //   })
  //   .then(response => {
  //     if(response.status !== 200) {
  //       url = `https://datenservice.kof.ethz.ch/api/v1/preview?mime=csv&schema=main&keys=${keys.join()}`
  //       fetch(url, {
  //         method: 'GET',
  //         headers: jwt === null ? {} : { Authorization: `Bearer ${jwt}` }
  //       })
  //       .then(response => response.text())
  //       .then(csv => {
  //         if(requestId.current === newRequestId) {
  //           loadData(csv)
  //           setDataWarning(true)
  //         }
  //       })
  //     } else  {
  //       response.text()
  //       .then(csv => {
  //         if(requestId.current === newRequestId) {
  //           loadData(csv)
  //           setDataWarning(false)
  //         }
  //       })
  //     }
  //   })
  //   .catch(function(error) {
  //     dataRef.current = [];
  //     setTransformedData([]);
  //     console.log('Request failed', error)
  //   });
  // }

  const loadData = (csv) => {
    const [header, ...lines] = csv.split("\n");
    var dataKeys = header.split(",");

    const data = lines
      .filter((x) => x !== "")
      .map((l) => {
        let out = {};
        const components = l.split(",");
        out.date = new Date(components[0]).getTime();
        for (let i = 1; i < dataKeys.length; i++) {
          out[dataKeys[i]] = Number.parseFloat(components[i]);
        }
        return out;
      });

    // setDateKey(dataKeys[0])

    dataKeys = dataKeys.slice(1, dataKeys.length);
    dataKeys.map((k) => {
      var vals = data.map((x) => x[k]);
      var start = vals.findIndex((x) => !isNaN(x));
      var end = findLastIndex(vals, (x) => !isNaN(x));
      for (let i = start + 1; i < end; i++) {
        if (isNaN(vals[i])) {
          vals[i] = vals[i - 1];
        }
      }
      data.map((x, i) => (x[k] = vals[i]));
    });

    dataRef.current = data;
    transformData();
  };

  const transformData = () => {
    // Transform data for plotting
    var newData = cloneDeep(dataRef.current);

    keys.map((k, i) => {
      if (transforms[i].log) {
        newData.map((x) => {
          x[k] = Math.log(x[k]);
        });
      }
      if (transforms[i].diff) {
        for (let j = newData.length - 1; j > 0; j--) {
          let val = newData[j][k];
          newData[j][k] = val - newData[j - 1][k];
        }
        newData[0][k] = NaN;
      }
    });

    var cutInd = transforms.findIndex((x) => x.limit);
    if (cutInd !== -1) {
      var k = keys[cutInd];
      newData = newData.filter((x) => !isNaN(x[k]));
    }

    var fitInd = transforms.findIndex((x) => x.fit);
    if (fitInd !== -1) {
      var k = keys[fitInd];
      var vals = newData.map((x) => x[k]).filter((val) => !isNaN(val));
      var refMean = mean(vals);
      var refStdv = std(vals);
      var others = keys.filter((key) => key != k);
      others.map((k) => {
        var vals = newData.map((x) => x[k]).filter((val) => !isNaN(val));
        var otherMean = mean(vals);
        var otherStdv = std(vals);
        newData.map((x) => {
          x[k] = ((x[k] - otherMean) / otherStdv) * refStdv + refMean;
        });
      });
    }

    setTransformedData(newData);
  };

  // Functions
  const formatValue = (value, lang) => {
    var postfix = "";
    var absValue = Math.abs(value);
    if (absValue >= 10 ** 3 && absValue < 10 ** 6) {
      value = value / 10 ** 3;
      postfix = ui.labels.thousand[lang];
    } else if (absValue >= 10 ** 6 && absValue < 10 ** 9) {
      value = value / 10 ** 6;
      postfix = ui.labels.million[lang];
    } else if (absValue >= 10 ** 9) {
      value = value / 10 ** 9;
      postfix = ui.labels.billion[lang];
    }

    return markThousands(parseFloat(value.toPrecision(4))) + postfix;
  };
  // Show div with nodata label when no data
  if (transformedData.length === 0) {
    return <NoData>{ui.labels.nodata[lang]}</NoData>;
  }

  const option = {
    xAxis: {
      type: "time",
      // data: transformedData.map(x => x.date)
    },
    title: {
      show: false,
    },
    tooltip: {
      trigger: "axis",
      show: true,
      textStyle: {
        fontSize: 12,
      },
      // formatter: (params) => {
      //   const str = params[0].axisValueLabel + "<br>" + params.map(param => param.seriesName + ":" + param.data[1] + "<br>").join("");
      //   return str;
      // }
    },
    // dataZoom: [{
    //   id: 'dataZoomX',
    //   type: 'inside',
    //   xAxisIndex: [0]
    // }],
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: (value) => formatValue(value, lang),
      },
    },
    legend: {
      show: false,
    },
    grid: {
      left: 70,
      top: 30,
      right: 30,
      bottom: 80,
    },
    dataZoom: [
      {
        type: "slider",
        show: true,
        showDetail: false,
        xAxisIndex: [0],
        // backgroundColor: 'rgb(255,255,255)',
        // fillerColor: 'rgba(0,0,0,0)',
        // borderColor: 'rgba(150,150,150)',
        // dataBackground: {
        //   areaStyle: {
        //     color: 'rgb(200,200,200)'
        //   },
        //   lineStyle: {
        //     color: 'rgb(100,100,100)'
        //   }
        // },
        // selectedDataBackground: {
        //   areaStyle: {
        //     color: 'rgb(150,150,150)'
        //   },
        //   lineStyle: {
        //     color: 'rgb(50,50,50)'
        //   }
        // },
        // handleStyle: {
        //   color: 'rgb(150,150,150)',
        //   borderColor: 'rgb(150,150,150)'
        // },
        // moveHandleStyle: {
        //   color: 'rgb(150,150,150)',
        //   borderColor: 'rgb(150,150,150)',
        //   shadowColor: 'rgb(150,150,150)'
        // },
        // brushStyle: {
        //   color: 'rgb(150,150,150)'
        // }
      },
      {
        type: "inside",
        xAxisIndex: 0,
      },
    ],
    // toolbox: {
    //   feature: {
    //       dataZoom: {
    //           yAxisIndex: 'none'
    //       },
    //       restore: {},
    //       saveAsImage: {}
    //   }
    // },
    series: keys.map((k, i) => ({
      name: k,
      type: "line",
      data: transformedData.map((x) => [x.date, x[k]]),
      showSymbol: false,
      itemStyle: {
        color: colors[i],
      },
      lineStyle: {
        color: colors[i],
        width: k === hoverKey ? 1.0 : 0.7,
      },
      emphasis: {
        lineStyle: {
          width: 0.7,
        },
      },
    })),
  };

  return (
    <div {...other}>
      <StyledReactECharts option={option} notMerge />
    </div>
    // <GraphBox>
    //   <GraphDiv>
    //     <ResponsiveContainer>
    //       <LineChart
    //         margin={{top: 0, right: 0, bottom: 0, left: 0 }}
    //         layout='horizontal'
    //         data={transformedData}
    //         onMouseDown={event => setRefArea({...refArea, left: event.activeLabel})}
    //         onMouseMove={event => refArea.left && setRefArea({...refArea, right: event.activeLabel})}
    //         onMouseUp={zoom}>
    //           <XAxis key={Math.random()} scale='time' type='number' domain={domain} padding={{ left: 10, right: 10 }} dataKey='date' tickFormatter={date => new Date(date).toLocaleString()}/>
    //           {/* <XAxis key={Math.random()} type='number' dataKey='date' allowDataOverflow padding={{ left: 10, right: 10 }}/> */}
    //           <YAxis key={Math.random()} type='number' tickFormatter={(value) => formatValue(value, lang)}/>
    //           {/* <XAxis key={Math.random()} dataKey='date' padding={{ left: 10, right: 10 }}/>
    //           <YAxis key={Math.random()} tickFormatter={(value) => formatValue(value, lang)}/> */}
    //           <CartesianGrid vertical={false}/>
    //           <Tooltip formatter={(value, name, props) => formatValue(value, lang)} labelFormatter={t => new Date(t).toLocaleString()}/>
    //           {keys.map((k, i) => (
    //             <Line
    //               dot={false}
    //               dataKey={k}
    //               key={k}
    //               stroke={colors[i]}
    //               strokeWidth={k===hoverKey ? 1.5 : 1}
    //               strokeOpacity={hoverKey===k || hoverKey===undefined ? 1 : 0.7}
    //               animationDuration={100}/>))}
    //           {refArea.left && refArea.right && <ReferenceArea x1={refArea.left} x2={refArea.right} strokeOpacity={0.3}/>}
    //       </LineChart>
    //     </ResponsiveContainer>
    //   </GraphDiv>
    //   <TimePeriodButtons>
    //     {timePeriodButtons}
    //   </TimePeriodButtons>
    // </GraphBox>
  );
};

// <div className="highlight-bar-charts" style={{ userSelect: 'none', width: '100%' }}>
//         <button type="button" className="btn update" onClick={this.zoomOut.bind(this)}>
//           Zoom Out
//         </button>

//         <ResponsiveContainer width="100%" height={400}>
//           <LineChart
//             width={800}
//             height={400}
//             data={data}
//             onMouseDown={(e) => this.setState({ refAreaLeft: e.activeLabel })}
//             onMouseMove={(e) => this.state.refAreaLeft && this.setState({ refAreaRight: e.activeLabel })}
//             // eslint-disable-next-line react/jsx-no-bind
//             onMouseUp={this.zoom.bind(this)}
//           >
//             <CartesianGrid strokeDasharray="3 3" />
//             <XAxis allowDataOverflow dataKey="name" domain={[left, right]} type="number" />
//             <YAxis allowDataOverflow domain={[bottom, top]} type="number" yAxisId="1" />
//             <YAxis orientation="right" allowDataOverflow domain={[bottom2, top2]} type="number" yAxisId="2" />
//             <Tooltip />
//             <Line yAxisId="1" type="natural" dataKey="cost" stroke="#8884d8" animationDuration={300} />
//             <Line yAxisId="2" type="natural" dataKey="impression" stroke="#82ca9d" animationDuration={300} />

//             {refAreaLeft && refAreaRight ? (
//               <ReferenceArea yAxisId="1" x1={refAreaLeft} x2={refAreaRight} strokeOpacity={0.3} />
//             ) : null}
//           </LineChart>
//         </ResponsiveContainer>
//       </div>
