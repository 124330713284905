import styled from "@emotion/styled";
import { Settings } from "@mui/icons-material";
import React, { useState } from "react";
import { useMatches, useNavigate } from "react-router-dom";
import { ui } from "../constants";
import { getUserPath } from "../utils";
import { Button } from "./Button";
import { ButtonMenu } from "./ButtonMenu";
import { Login } from "./Login";
import { SettingsMenu } from "./SettingsMenu";
import { useSettings } from "./SettingsProvider";
import { Title } from "./Title";
import { useUserData } from "./UserDataProvider";
import { useUser } from "./UserProvider";

const TitleBar = styled.div`
  display: flex;
  width: 100%;
  height: 40px;
  border-bottom: 0px solid;
  background-color: ${ui.stdColor60};
`;

const TabButtons = styled.div`
  display: flex;
`;

const TabButton = styled(Button)`
  background-color: ${ui.stdColor60};
  color: black;
  font-size: 1.1em;
  &:hover {
    background-color: ${ui.stdColor50};
    color: black;
  }
  &:active {
    background-color: ${ui.stdColor100};
    color: white;
  }
  &[aria-pressed="true"] {
    background-color: ${ui.stdColor100};
    color: white;
  }
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const VariaButton = styled(Button)`
  color: black;
  background-color: ${ui.stdColor60};
  &:hover {
    background-color: ${ui.stdColor50};
    color: black;
  }
  &:active {
    background-color: ${ui.stdColor100};
    color: white;
  }
  &[aria-pressed="true"] {
    background-color: ${ui.stdColor100};
    color: white;
  }
  height: 100%;
  font-size: 1em;
`;

const VariaButtons = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-end;
`;

const SettingsButton = styled(VariaButton)`
  padding-right: 8px;
  /* margin-right: 7px; */
  /* border: 1px solid; */
`;

export const MainMenu = () => {
  const { settings } = useSettings();
  const { auth } = useUser();
  const { sets } = useUserData();
  const [showSettingsState, setShowSettings] = useState(false);
  const navigate = useNavigate();
  const match = useMatches();
  const currentRoute = match.at(match.length - 1);

  const isActive = (path) =>
    `${getUserPath(auth)}${path}` === currentRoute.pathname;
  const navigateTo = (path) => {
    if (!isActive(path)) {
      navigate(`${getUserPath(auth)}${path}`);
    }
  };
  const settingsButton = (
    <SettingsButton
      tooltip={ui.tooltips.settings[settings.lang]}
      icon={<Settings />}
      log="settings_menu"
      iconPosition="left"
      iconChildrenSpace="0.35em"
      ariaPressed={showSettingsState}
      onMouseDown={() => setShowSettings(true)}
    >
      {ui.labels.settings[settings.lang]}
    </SettingsButton>
  );

  return (
    <TitleBar>
      <Title />
      <TabButtons>
        <TabButton
          tooltip={ui.tooltips.metaSearch[settings.lang]}
          ariaPressed={isActive("/")}
          onMouseDown={() => {
            navigateTo("/");
          }}
        >
          {ui.labels.metaSearch[settings.lang]}
        </TabButton>
        <TabButton
          tooltip={ui.tooltips.keySearch[settings.lang]}
          ariaPressed={isActive("/keys")}
          onMouseDown={() => {
            navigateTo("/keys");
          }}
        >
          {ui.labels.keySearch[settings.lang]}
        </TabButton>
        <TabButton
          tooltip={ui.tooltips.favouritesView[settings.lang]}
          ariaPressed={isActive("/collections")}
          onMouseDown={() => {
            navigateTo("/collections");
          }}
        >
          {`${ui.labels.collections[settings.lang]} (${
            Object.keys(sets).length
          })`}
        </TabButton>
        {auth.loggedIn && process.env.REACT_APP_VERSION==="intern" ? (
          <TabButton
            tooltip={ui.tooltips.dataImportStatus[settings.lang]}
            ariaPressed={isActive("/import")}
            onMouseDown={() => {
              navigateTo("/import");
            }}
          >
            {ui.labels.dataImportStatus[settings.lang]}
          </TabButton>
        ) : (
          ""
        )}
      </TabButtons>
      <FlexGrow />
      <VariaButtons>
        <ButtonMenu
          button={settingsButton}
          show={showSettingsState}
          horizontal={true}
          onExit={() => setShowSettings(false)}
        >
          <SettingsMenu />
        </ButtonMenu>
        {/* <HelpButton
                tooltip={ui.tooltips.help[settings.lang]}
                icon={<Help/>}
                onMouseDown={() => window.open('http://apps.kof.ethz.ch/howto/data.html')}/> */}
        <Login />
      </VariaButtons>
    </TitleBar>
  );
};
