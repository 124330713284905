import React, { memo, useRef, useEffect } from "react";
import { Button } from "./Button";
import { map, flatten } from "lodash";
import { markThousands, upperCaseFirst, checkMissing } from "../utils";
import Highlighter from "react-highlight-words";
import { ScrollBox } from "./ScrollBox";
import { reduce, isEqual } from "lodash";
import { ui, colors } from "../constants";
import { usePrevious } from "./Various";
import styled from "@emotion/styled/macro";
import {
  ExpandLess,
  ExpandMore,
  NavigateNext,
  Done,
  Clear
} from "@mui/icons-material";
import { css } from "@emotion/react"; 
import { useSettings } from "./SettingsProvider";

const ApplyFilterButton = styled(Button)`
  font-weight: 400;
  background-color: ${ui.stdColor80};
  color: white;
  padding: 0px 20px 0px 20px;
  border-left: 2px solid ${ui.button.backgroundColor};
  &:hover {
    background-color: ${ui.stdColor60};
  }
  &:active {
    background-color: ${ui.stdColor50};  
  }
`;

const SelectionCounter = styled.div`
  font-weight: 700;
  display: flex;
  align-items: center;
  background-color: ${ui.stdColor80};
  color: white;
  padding-left: 5px;
  padding-right: 5px;
  border-left: 2px solid ${ui.button.backgroundColor};
`;

const ItemButton = styled(Button)`
  background-color: white;
  border-bottom: ${(props) => props.borderBottom};
  padding: 0.4em 0.5em 0.4em 0.5em;
  border-left: ${(props) => (props.selectable ? "2px solid white" : "none")};

  &:hover {
    background-color: ${(props) =>
      props.selected ? ui.stdColor10 : colors.grey4};
    border-left-color: ${(props) => (props.selected ? "black" : colors.grey4)};
  }
  &:active {
    background-color: ${colors.grey10};
  }
  &:last-child {
    border-bottom: none;
  }
  ${(props) =>
    props.selected &&
    css`
      border-left-color: black;
      background-color: ${ui.stdColor10};
    `}
  ${(props) =>
    props.focus &&
    css`
      /* border-left: 2px solid black; */
      background-color: ${colors.grey4};
    `}
`;

const CategoryItem = styled.div`
  width: 100%;
  display: block;
  text-align: left;
`;

const KeyChunkHighlighter = styled(Highlighter)`
  font-size: 1em;
  margin-left: 1em;
  color: grey;
`;

const RelatedCategories = styled.div`
  font-weight: 400;
  font-size: 1em;
  margin-top: 0.25em;
  color: grey;
`;

const ValueCountLabel = styled.span`
  float: right;
  font-size: 1em;
  color: grey;
`;

const ValueHighlighter = styled(Highlighter)`
  font-size: 1em;
  margin-left: 1em;
`;
const WordHighlighter = styled(Highlighter)`
  font-size: 1em;
  font-weight: 400;
`;

const RelatedCategory = styled.div`
  display: inline-flex;
  align-items: center;
`;


const ItemScrollBox = styled(ScrollBox)`
  display: flex;
  flex-direction: column;
  position: relative;
`

const CategoryItemShowCount = styled.div`
  font-weight: 400;
`;

const CategoryBar = styled.div`
  display: flex;
  position: sticky;
  top: 0px;
  height: 30px;
`;

const ExpandCategoryButton = styled(Button)`
  flex-grow: 1;
  ${(props) =>
    props.focus &&
    css`
      background-color: ${ui.button.backgroundColorHover};
    `}
`;

const CommonTitle = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const SuggestionsView = memo(
  ({
    suggestions,
    categoriesExpanded,
    searchTerms,
    searchValue,
    onExpandCategory,
    onReduceCategory,
    onRemoveSearchCategory,
    searchCategory,
    onSelectCategoryItem,
    onSelectCategory,
    onSelectAll,
    resultCount,
    onLoad,
    focusIndex,
    searchMode,
    searchHitCount,
    expansion,
    suggestedWords,
    onChangeSearchValue,
    selectedItems,
    addSelection,
    removeSelection,
    setSelectedItems,
    ...other
  }) => {
    const { settings } = useSettings();
    const { lang, multiSelect, showKeychunks } = settings;

    const scrollRef = useRef(null);
    const focusRef = useRef(null);

    const prevSuggestions = usePrevious(suggestions);
    const prevFocusIndex = usePrevious(focusIndex);

    useEffect(() => {
      if (
        scrollRef.current &&
        !expansion &&
        !isEqual(prevSuggestions, suggestions)
      ) {
        //        (!isEqual(prevCategoryItemShowCount, categoryItemShowCount) ||
        //        !isEqual(prevSuggestions, suggestions))) {
        scrollRef.current.scrollTop = 0;
      }
      if (
        focusRef.current &&
        scrollRef.current &&
        !isEqual(prevFocusIndex, focusIndex)
      ) {
        if (focusRef.current.offsetTop < scrollRef.current.scrollTop) {
          scrollRef.current.scrollTop = focusRef.current.offsetTop;
        } else if (
          focusRef.current.offsetTop + focusRef.current.offsetHeight >
          scrollRef.current.scrollTop + scrollRef.current.clientHeight
        ) {
          scrollRef.current.scrollTop =
            focusRef.current.offsetTop +
            focusRef.current.offsetHeight -
            scrollRef.current.clientHeight;
        }
      }
    });

    var searchItem = null;
    if(searchMode === "key" && Object.keys(suggestions).length === 0 && resultCount > 1) {
      searchItem = 
        <ItemButton
          focus={false}
          ref={false ? focusRef : undefined}
          onMouseDown={(event) => {
            onChangeSearchValue(searchValue + ".");
            event.preventDefault();}}>
          <CategoryItem>
            {"[.]"}
          </CategoryItem>
        </ItemButton>
    }
    else if(searchMode === "meta" && searchValue.length > 0 && (Object.keys(suggestions).length > 0 || suggestedWords.length > 0)) {
      searchItem = 
        <ItemButton
          focus={false}
          ref={false ? focusRef : undefined}
          onMouseDown={(event) => {
            onSelectAll(searchValue);
            event.preventDefault();
          }}>
          <CategoryItem>
            {searchValue}
          </CategoryItem>
        </ItemButton>
    }
    
    var wordItems = suggestedWords.map((x, i) => {
      const focused = focusIndex.category === -1 && focusIndex.item === i;
      const item =
        <ItemButton
          focus={focused}
          ref={focused ? focusRef : undefined}
          onMouseDown={(event) => {
            onSelectAll(x.word);
            event.preventDefault();
          }}
        >
          <CategoryItem>
            <WordHighlighter
              textToHighlight={x.word}
              searchWords={searchTerms}
              highlightStyle={ui.searchHighlightStyle}
            />
          </CategoryItem>
        </ItemButton>
      return item;
    });

    var catCount = 0;
    var items = map(suggestions, (suggestion) => {
      var category = suggestion.category;
      let selectedCategory =
        selectedItems.length !== 0 && category === selectedItems[0].category;
      let selectedCount = selectedCategory ? selectedItems.length : 0;
      var expanded = categoriesExpanded.includes(category);
      var focused = focusIndex.category === catCount && focusIndex.item === -1;
      const categoryItem =
        <CategoryBar>
          <ExpandCategoryButton
            focus={focused}
            log="suggestions_expand_category"
            ref={focused ? focusRef : undefined}
            icon={expanded ? <ExpandLess/> : <ExpandMore/>}
            iconPosition="right"
            onMouseDown={
              expanded
                ? (event) => {
                    onReduceCategory(category);
                    event.preventDefault();
                  }
                : (event) => {
                    onExpandCategory(category);
                    event.preventDefault();
                  }
            }
          >
            <CommonTitle>
              <Highlighter
                textToHighlight={upperCaseFirst(suggestion.category)}
                searchWords={searchTerms}
                highlightStyle={ui.searchHighlightStyle}
              />
              <FlexGrow/>
              <CategoryItemShowCount>
                {suggestion.total}
              </CategoryItemShowCount>
            </CommonTitle>
          </ExpandCategoryButton>
          {multiSelect && selectedCount > 0 && 
          <>
            <ApplyFilterButton
              icon={<Clear/>}
              onMouseDown={(event) => {
                setSelectedItems([]);
                event.preventDefault();
                event.stopPropagation();
              }}/>
            <ApplyFilterButton
              icon={<Done />}
              onMouseDown={(event) => {
                onSelectCategoryItem(selectedItems);
                setSelectedItems([]);
                event.preventDefault();
                event.stopPropagation();
              }}>
            </ApplyFilterButton>
            <SelectionCounter>{selectedCount}</SelectionCounter>
          </>}
        </CategoryBar>

      if (!expanded) {
        return [categoryItem];
      }

      var resultItems = [];
      const categoryItemCount = suggestion.results.length;
      for (let i = 0; i < categoryItemCount; i++) {
        let elem = suggestion.results[i];
        focused = focusIndex.category === catCount && focusIndex.item === i;
        let selectedCategory =
          selectedItems.length !== 0 && category === selectedItems[0].category;
        let selected =
          selectedCategory && selectedItems.find((x) => x.id === elem.id);

        const related = elem.relatedCategories.map(({ category, value }, j) => (
          <RelatedCategory key={category}>
            <span>{upperCaseFirst(category + " " + value)}</span>
            {j === elem.relatedCategories.length - 1 ? null : (
              <NavigateNext fontSize="small" />
            )}
          </RelatedCategory>
        ));

        const item =
          <ItemButton
            key={elem.id}
            borderBottom={
              i < categoryItemCount - 1 || !expanded
                ? `1px solid ${ui.dividerColor}`
                : "0px"
            }
            focus={focused}
            selectable={multiSelect}
            selected={selected}
            ref={focused ? focusRef : undefined}
            onMouseDown={
              searchMode === "meta"
                ? (event) => {
                    multiSelect
                      ? selected
                        ? removeSelection(elem)
                        : addSelection(elem)
                      : onSelectCategoryItem([elem]);
                    event.preventDefault();
                  }
                : (event) => {
                    onSelectCategoryItem([elem]);
                    event.preventDefault();
                  }
            }
          >
            <CategoryItem>
              {searchMode === "key" ? (
                <div style={{ fontWeight: 400 }}>
                  <Highlighter
                    textToHighlight={"[" + elem.keyChunk + "]"}
                    searchWords={searchTerms}
                    highlightStyle={ui.searchHighlightStyle}
                  />
                  <ValueHighlighter
                    textToHighlight={upperCaseFirst(elem.value)}
                    searchWords={searchTerms}
                    highlightStyle={ui.searchHighlightStyle}
                  />
                  <ValueCountLabel>{markThousands(elem.count)}</ValueCountLabel>
                </div>
              ) : (
                <>
                  <div style={{ fontWeight: 400 }}>
                    <Highlighter
                      textToHighlight={upperCaseFirst(elem.value)}
                      searchWords={searchTerms}
                      highlightStyle={ui.searchHighlightStyle}
                    />
                    {elem.keyChunk !== "" &&
                      (showKeychunks ||
                        checkMissing(elem.value, lang) ||
                        reduce(
                          searchTerms,
                          (match, term) =>
                            match || elem.keyChunk.match(new RegExp(term, "i")),
                          false,
                        )) && (
                        <KeyChunkHighlighter
                          textToHighlight={"[" + elem.keyChunk + "]"}
                          searchWords={searchTerms}
                          highlightStyle={ui.searchHighlightStyle}
                        />
                      )}
                    <ValueCountLabel>
                      {markThousands(elem.count)}
                    </ValueCountLabel>
                  </div>
                  {related.length !== 0 && (
                    <RelatedCategories>{related}</RelatedCategories>
                  )}
                </>
              )}
            </CategoryItem>
          </ItemButton>;

        resultItems.push(item);
      }

      catCount++;
      return [categoryItem, ...resultItems];
    });

    items = flatten(items);
    items = [searchItem, ...wordItems, ...items];

    return (
      <>
        {items.length > 0 &&
          <ItemScrollBox onScrollEnd={onLoad} {...other} scrollRef={scrollRef}>
            {items}
          </ItemScrollBox>}
      </>
    );
  },
  (prevProps, nextProps) => {
    return (
      nextProps.focusIndex === prevProps.focusIndex &&
      isEqual(nextProps.categoriesExpanded, prevProps.categoriesExpanded) &&
      isEqual(nextProps.suggestions, prevProps.suggestions) &&
      isEqual(nextProps.searchTerms, prevProps.searchTerms) &&
      isEqual(nextProps.selectedItems, prevProps.selectedItems) &&
      isEqual(nextProps.suggestedWords, prevProps.suggestedWords) &&
      nextProps.keySearch === prevProps.keySearch &&
      nextProps.lang === prevProps.lang &&
      nextProps.expansion === prevProps.expansion &&
      nextProps.searchHitCount === prevProps.searchHitCount &&
      nextProps.searchValue === prevProps.searchValue
    );
  },
);