import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { Button } from "./Button";
import { ui } from "../constants";
import { Transition } from "react-transition-group";
import styled from "@emotion/styled";
import { Clear, Done } from "@mui/icons-material";

const StyledDialog = styled.div`
  position: fixed;
  box-shadow: 0 0 7px 0 grey;
  z-index: 2;
  background-color: ${ui.menu.backgroundColor};
  left: ${(props) => props.left}px;
  top: ${(props) => props.top}px;
`;

const Screen = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: default;
`;

const TransitionOpacity = styled.div`
  transition: opacity 0.2s;
  opacity: ${(props) => ui.transitionOpacity[props.state]};
`;

const ContentMeasure = styled.div`
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
`;

const CancelButton = styled(Button)`
  margin-left: 15px;
`;

const OKButton = styled(Button)``;

const Buttons = styled.div`
  margin-top: 15px;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 1.1em;
  margin-bottom: 15px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px 15px;
`;

export const Dialog = ({
  show = false,
  onCancel,
  onSave,
  children,
  title,
  buttonNames,
  okEnabled = true,
  contentHeight,
  contentWidth,
}) => {
  const [viewport, setViewport] = useState({ height: 0, width: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const dialogRef = useRef(null);
  const divRef = useRef(null);

  const resize = () => {
    setViewport({ width: window.innerWidth, height: window.innerHeight });
  };

  useLayoutEffect(() => {
    const dialog = dialogRef.current;
    if (
      dialog &&
      (size.width !== dialog.clientWidth || size.height !== dialog.clientHeight)
    ) {
      setSize({ width: dialog.clientWidth, height: dialog.clientHeight });
    }
  });

  useEffect(() => {
    window.addEventListener("resize", resize);
    resize();
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);

  const content = (
    <Content
      onKeyDown={(event) => {
        if (event.key === "Enter" && okEnabled) onSave();
      }}
    >
      <Title>{title}</Title>
      {children}
      <FlexGrow />
      <Buttons>
        <OKButton
          disabled={!okEnabled}
          icon={<Done />}
          onMouseDown={(event) => {
            onSave();
            event.stopPropagation();
          }}
        >
          {buttonNames.ok}
        </OKButton>
        <CancelButton
          icon={<Clear />}
          onMouseDown={(event) => {
            onCancel();
            event.stopPropagation();
          }}
        >
          {buttonNames.cancel}
        </CancelButton>
      </Buttons>
    </Content>
  );

  contentWidth = contentWidth ? contentWidth : size.width;
  contentHeight = contentHeight ? contentHeight : size.height;

  const dispatchMouseout = () =>
    divRef.current.dispatchEvent(
      new MouseEvent("mouseout", {
        view: window,
        bubbles: true,
        cancelable: true,
      }),
    );

  return (
    <div ref={divRef}>
      {show && <ContentMeasure ref={dialogRef}>{content}</ContentMeasure>}
      <Transition
        in={show}
        timeout={200}
        unmountOnExit={true}
        onExited={dispatchMouseout}
      >
        {(state) => (
          <TransitionOpacity state={state}>
            <Screen
              onMouseDown={(event) => {
                onCancel();
                event.stopPropagation();
              }}
            />
            <StyledDialog
              left={viewport.width / 2 - contentWidth / 2}
              top={viewport.height / 2 - contentHeight / 2}
              onMouseDown={(event) => {
                event.stopPropagation();
                event.preventDefault();
              }}
            >
              {content}
            </StyledDialog>
          </TransitionOpacity>
        )}
      </Transition>
    </div>
  );
};
