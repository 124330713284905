import React, { useRef, useState } from "react";
import { SetNameDialog } from "./SetNameDialog";
import { ButtonMenu } from "./ButtonMenu";
import { map } from "lodash";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { PlaylistAdd } from "@mui/icons-material";
import { CommonIconButton, MenuItemButton } from "./Various";
import { copyToClipboard, upperCaseFirst } from "../utils";
import { Notification } from "./Various";
import { useSettings } from "./SettingsProvider";
import { useUserData } from "./UserDataProvider";

const OptionsButton = styled(CommonIconButton)`
  visibility: ${(props) => props.visibility};
`;

const TargetSetLabel = styled.span`
  font-weight: 700;
  font-style: italic;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ResultsTSButtonMenu = ({
  content,
  hover,
  selectSeries,
  unselectSeries,
  selected,
  onSetFilter,
}) => {
  const {
    settings: { lang },
  } = useSettings();
  const { sets, setFunctions: setFunc } = useUserData();
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [showNotif, setShowNotif] = useState(false);

  var notification = useRef("");

  const notify = (text) => {
    notification.current = text;
    setShowNotif(true);
    setTimeout(() => setShowNotif(false), 500);
  };
  // var setNames = map(sets, (set, setName) => ({ setName: setName, found: set.map(e => e.key).includes(content.key) }))
  // setNames = orderBy(setNames, 'found', 'desc');

  const items = map(sets, (set, setName) => {
    return (
      <MenuItemButton
        key={setName}
        onMouseDown={(event) => {
          setFunc.addToSet(setName, [content.key]);
          setShow(false);
          event.stopPropagation();
        }}
      >
        <div>
          <span>{ui.labels.addToSet[lang]}</span>
          <TargetSetLabel>{setName}</TargetSetLabel>
        </div>
      </MenuItemButton>
    );
  });

  const buttonPreview = (
    <MenuItemButton
      dividerBottom={items.length > 0}
      onMouseDown={(event) => {
        setShow(false);
        selected ? unselectSeries(content.key) : selectSeries(content.key);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {selected
        ? ui.labels.removeFromPreview[lang]
        : ui.labels.addToPreview[lang]}
    </MenuItemButton>
  );

  const buttonNewSet = (
    <MenuItemButton
      dividerTop
      onMouseDown={(event) => {
        setDialog("");
        setShow(false);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {ui.labels.addToNewSet[lang]}
    </MenuItemButton>
  );

  const filterButton = (
    <MenuItemButton
      dividerTop
      onMouseDown={(event) => {
        onSetFilter(content);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <span>{ui.labels.setAsFilter[lang]}</span>
    </MenuItemButton>
  );

  const metaText = content.meta
    .map(
      (meta) =>
        upperCaseFirst(meta.category) + ": " + upperCaseFirst(meta.value),
    )
    .join("\n");

  const buttonCopyKey = (
    <MenuItemButton
      dividerTop
      log="results_ts_options_copy_key"
      onMouseDown={(event) => {
        copyToClipboard(content.key);
        notify(ui.labels.notifCopied[lang]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      {ui.labels.copyKeyToClipboard[lang]}
    </MenuItemButton>
  );
  
  const url = content.meta.find(x => x.category=="URL")
  const buttonWebsite = url ?
    <MenuItemButton
      dividerTop
      log="results_ts_options_website"
      onMouseDown={(event) => {
        window.open(url.value);
        setShow(false);
        event.stopPropagation();
      }}>
    {ui.labels.gotoWebsite[lang]}
  </MenuItemButton> : null;

  const buttonCopyMetadata = (
    <MenuItemButton
      dividerTop
      log="results_ts_options_copy_meta"
      onMouseDown={(event) => {
        copyToClipboard(metaText);
        notify(ui.labels.notifCopied[lang]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      {ui.labels.copyMetaToClipboard[lang]}
    </MenuItemButton>
  );

  const button = (
    <OptionsButton
      tooltip={ui.tooltips.addToFavourites[lang]}
      // visibility={hover ? 'visible' : 'hidden'}
      ariaPressed={show}
      log="results_ts_options_menu"
      icon={<PlaylistAdd />}
      onMouseDown={(event) => {
        setShow(true);
        event.stopPropagation();
      }}
    />
  );

  return (
    <>
      <ButtonMenu button={button} show={show} onExit={() => setShow(false)}>
        <MenuItems>
          {buttonPreview}
          {items}
          {buttonNewSet}
          {buttonCopyKey}
          {buttonCopyMetadata}
          {buttonWebsite}
          {filterButton}
        </MenuItems>
      </ButtonMenu>
      <SetNameDialog
        show={dialog !== null}
        value={dialog}
        alreadyExists={Object.keys(sets).includes(dialog)}
        onCancel={() => setDialog(null)}
        onSave={() => {
          setFunc.addToSet(dialog, [content.key]);
          setDialog(null);
        }}
        onChange={(value) => setDialog(value)}
      />
      <Notification show={showNotif}>{notification.current}</Notification>
    </>
  );
};
