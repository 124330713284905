import { darkenColor, lightenColor } from "./utils";

const stdColor = "#007a92";
// const stdColor = "#004CAA";

// const stdColor = darkenColor('white', 0.7);

export const colors = {
  kof: {
    eth_4_100: "#72791c",
    eth_4_60: "#a9af66",
    eth_5_100: "#91056a",
    eth_5_60: "#cc67a7",
    eth_5_30: "#e6b3d3",
    eth_6_100: "#6f6f6e",
    eth_6_60: "#a9a9a8",
    eth_7_100: "#a8322d",
    eth_7_50: "#e19794",
    eth_8_100: "#007a92",
    eth_8_60: "#66b0c2",
    eth_8_30: "#b3d7e0",
    eth_8_20: "#cce5eb",
  },
  plot: [
    "#000000",
    "#e41a1c",
    "#377eb8",
    "#4daf4a",
    "#984ea3",
    "#ff7f00",
    "#ffff33",
    "#a65628",
    "#f781bf",
    "#999999",
  ],
  grey2: darkenColor("white", 0.02),
  grey3: darkenColor("white", 0.03),
  grey4: darkenColor("white", 0.04),
  grey5: darkenColor("white", 0.05),
  grey10: darkenColor("white", 0.1),
  grey15: darkenColor("white", 0.15),
  grey20: darkenColor("white", 0.2),
  grey25: darkenColor("white", 0.25),
  grey30: darkenColor("white", 0.3),
  grey40: darkenColor("white", 0.4),
  grey50: darkenColor("white", 0.5),
  grey60: darkenColor("white", 0.6),
  grey70: darkenColor("white", 0.7),
  grey80: darkenColor("white", 0.8),
  grey90: darkenColor("white", 0.9),
  grey100: "black",
  stdColor130: darkenColor(stdColor, 0.3),
  stdColor120: darkenColor(stdColor, 0.2),
  stdColor110: darkenColor(stdColor, 0.1),
  stdColor100: stdColor,
  stdColor10: lightenColor(stdColor, 0.9),
  stdColor20: lightenColor(stdColor, 0.8),
  stdColor30: lightenColor(stdColor, 0.7),
  stdColor40: lightenColor(stdColor, 0.6),
  stdColor50: lightenColor(stdColor, 0.5),
  stdColor60: lightenColor(stdColor, 0.4),
  stdColor80: lightenColor(stdColor, 0.2),
};

export const ui = {
  stdColor100: stdColor,
  stdColor5: lightenColor(stdColor, 0.95),
  stdColor10: lightenColor(stdColor, 0.9),
  stdColor20: lightenColor(stdColor, 0.8),
  stdColor30: lightenColor(stdColor, 0.7),
  stdColor40: lightenColor(stdColor, 0.6),
  stdColor50: lightenColor(stdColor, 0.5),
  stdColor60: lightenColor(stdColor, 0.4),
  stdColor80: lightenColor(stdColor, 0.2),

  dividerColor: darkenColor("white", 0.25),
  // backgroundColor: '#f6f8ff',
  backgroundColor: darkenColor("white", 0.025),
  hoverColor: darkenColor("white", 0.03),
  titleBorderBottom: `3px solid ${stdColor}`,
  boxShadow: `0 0 0px 0px ${colors.grey30}`,
  boxBorder: `1px solid ${colors.grey30}`,
  border: `1px solid ${darkenColor("white", 0.25)}`,
  button: {
    backgroundColor: lightenColor(stdColor, 0.7),
    border: `0px solid ${lightenColor("black", 0.7)}`,
    borderRadius: "1px",
    borderColor: stdColor,
    backgroundColorActive: lightenColor(stdColor, 0.75),
    colorAriaPressed: "white",
    colorActive: "black",
    color: "black",
    colorDisabled: colors.grey50,
    backgroundColorHover: lightenColor(stdColor, 0.6),
    backgroundColorAriaPressed: stdColor,
    backgroundColorFocus: lightenColor(stdColor, 0.6),
    backgroundColorDisabled: lightenColor(stdColor, 0.8),
    iconColor: lightenColor("black", 0.4),
    iconColorHover: lightenColor("black", 0.2),
    iconColorPressed: "black",
    textColor: "black",
    colorHover: "black",
    textColorDisabled: "grey",
    textColorAriaPressed: "white",
    colorTransitionTime: 150,
    iconTransformTransitionTime: 200,
  },
  menu: {
    backgroundColor: darkenColor("white", 0),
    backgroundColorHover: colors.stdColor20,
    backgroundColorActive: colors.stdColor30,
    backgroundColorFocus: colors.stdColor40,
    backgroundColorToggled: stdColor,
  },
  input: {
    borderRadius: "1px",
  },
  modalTransitionTime: 200,
  dialogTransitionTime: 200,
  hoverTransitionTime: 200,
  warnColor: "#eb0000",
  filterHighlightStyle: {
    //color: '#d83200'
    color: "#cc3000",
  },
  searchHighlightStyle: {
    //color: '#d83200',
    color: "#cc3000",
    fontWeight: 700,
  },
  transitionOpacity: { entering: 1, entered: 1, exiting: 0, exited: 0 },
  tooltips: {
    resultsOptions: {
      en: "Show options for filtered time series",
      de: "Optionen für gefilterte Zeitreihen anzeigen",
    },
    // addToFavourites: {en: 'Add time series to a set or remove from a set', de: 'Zeitreihe zu einem Set hinzufügen oder von einem Set entfernen'},
    addToFavourites: {
      en: "Show options for this time series",
      de: "Optionen für diese Zeitreihe anzeigen",
    },
    addToSet: {
      en: "Show options for this collection",
      de: "Optionen für diese Kollektion anzeigen",
    },
    // addSetToSet: {en: 'Add time series in set to another set or remove from another set', de: 'Zeitreihen im Set zu einem anderen Set hinzufügen oder von einem anderen Set entfernen'},
    addToPlot: {
      en: "Add time series to preview",
      de: "Zeitreihe zu Vorschau hinzufügen",
    },
    removeFromPlot: {
      en: "Remove time series from preview",
      de: "Zeitreihe aus Vorschau entfernen",
    },
    expandMeta: { en: "Show all metadata", de: "Alle Metadaten anzeigen" },
    reduceMeta: {
      en: "Show only important metadata",
      de: "Nur wichtige Metadaten anzeigen",
    },
    plotTransform: {
      limit: {
        en: "Limit all series in the plot to the time period of this series",
        de: "Alle Reihen im Plot auf die Zeitperiode dieser Reihe limitieren",
      },
      fit: {
        en: "Transform all series in the plot to the mean and variance of this series",
        de: "Alle Reihen im Plot auf den Durchschnitt und die Varianz dieser Reihe transformieren",
      },
      diff: {
        en: "Take the first difference of the series",
        de: "Erste Differenz der Reihe nehmen",
      },
      log: {
        en: "Take the logarithm of the series",
        de: "Logarithmus der Reihe nehmen",
      },
      last120: {
        en: "Show newest 120 values of the series",
        de: "Die neusten 120 Werte der Reihen anzeigen",
      },
      last60: {
        en: "Show newest 60 values of the series",
        de: "Die neusten 60 Werte der Reihen anzeigen",
      },
      last24: {
        en: "Show newest 24 values of the series",
        de: "Die neusten 24 Werte der Reihen anzeigen",
      },
    },
    filter: {
      en: "Filter time series with this category metadata",
      de: "Zeitreihen mit diesen Metadaten filtern",
    },
    searchCategory: {
      en: "Limit search to this category only",
      de: "Suche auf diese Kategorie einschränken",
    },
    expandCategory: {
      en: "Show metadata of this category",
      de: "Metadaten dieser Kategorie einblenden",
    },
    reduceCategory: {
      en: "Hide metadata of this category",
      de: "Metadaten dieser Kategorie ausblenden",
    },
    clearSearch: {
      en: "Clear the search field",
      de: "Text im Suchfeld löschen",
    },
    removeSearchCategory: {
      en: "Stop limiting search to this category",
      de: "Suche nicht mehr auf diese Kategorie einschränken",
    },
    filterTerms: {
      en: "Filter time series with the search terms",
      de: "Zeitreihen mit Suchbegriffen filtern",
    },
    clearFilters: { en: "Stop filtering", de: "Filtern stoppen" },
    removeFilter: {
      en: "Stop filterting with this metadata",
      de: "Filtern mit diesen Metadaten stoppen",
    },
    addCategoryFilter: {
      en: "Filter with additional metadata of this category",
      de: "Mit zusätzlichen Metadaten dieser Kategorie filtern",
    },
    removeFilterTerm: {
      en: "Stop filterting with this search term",
      de: "Filtern mit diesem Suchbegriff stoppen",
    },
    langEN: {
      en: "Switch to English language",
      de: "Zu englischer Sprache wechseln",
    },
    langDE: {
      en: "Switch to German language",
      de: "Zu deutscher Sprache wechseln",
    },
    favouritesView: {
      en: "Preview, edit and export your time series collections",
      de: "Zeitreihen-Kollektionen anschauen, editieren und exportieren",
    },
    keySearch: {
      en: "Type time series keys with autocompletion",
      de: "Zeitreihen-Keys mit Autovervollständigung tippen",
    },
    metaSearch: {
      en: "Find time series by their descriptions",
      de: "Zeitreihen nach ihren Beschreibungen durchsuchen",
    },
    dataImportStatus: {
      en: "List of datasets imported from external sources",
      de: "Liste der aus externen Quellen importierten Datasets",
    },
    plainText: {
      en: "Show time series keys as plain text",
      de: "Zeitreihen-Keys als Text anzeigen",
    },
    RVector: {
      en: "Show time series keys as R-Vector",
      de: "Zeitreihen-Keys als R-Vektor anzeigen",
    },
    RCodeKOF: {
      en: "Show R-Code to load time series from inside KOF",
      de: "R-Code zum Laden der Zeitreihen von innerhalb der KOF anzeigen",
    },
    RCodeExtern: {
      en: "Show R-Code to load time series from outside KOF",
      de: "R-Code zum Laden der Zeitreihen von ausserhalb der KOF anzeigen",
    },
    downloadLink: {
      en: "Show link to download time series in various formats",
      de: "Link für das Runterladen von Zeitreihen in verschiedenen Formaten anzeigen",
    },
    downloadCSV: {
      en: "Show link for CSV download",
      de: "Link für CSV-Download anzeigen",
    },
    downloadXLSX: {
      en: "Show link for Excel download",
      de: "Link für Excel-Download anzeigen",
    },
    downloadJSON: {
      en: "Show link for JSON download",
      de: "Link für JSON-Download anzeigen",
    },
    downloadMeta: {
      en: "Show link for metadata download",
      de: "Link für Metadaten-Download anzeigen",
    },
    downloadTS: {
      en: "Show link for time series download",
      de: "Link für Zeitreihen-Download anzeigen",
    },
    download: {
      en: "Download data in selected format",
      de: "Daten im gewählten Format runterladen",
    },
    copyToClipboard: {
      en: "Copy text to clipboard",
      de: "Text in Zwischenablage kopieren",
    },
    tooltipsOn: { en: "Tooltips on", de: "Tooltips an" },
    tooltipsOff: { en: "Tooltips off", de: "Tooltips ein" },
    keychunksOn: {
      en: "Hide key chunks in metadata",
      de: "Key-Kürzel in Metadaten ausblenden",
    },
    keychunksOff: {
      en: "Show key chunks in metadata",
      de: "Key-Kürzel in Metadaten einblenden",
    },
    newSet: {
      en: "Create new time series collection",
      de: "Neue Zeitreihen-Kollektion erstellen",
    },
    editSet: {
      en: "Change collection name",
      de: "Namen der Kollektion ändern",
    },
    deleteSet: { en: "Delete collection", de: "Kollektion löschen" },
    tsMenu: {
      en: "Show options for this time series",
      de: "Optionen für diese Zeitreihe anzeigen",
    },
    previewWarnLogin: {
      en: "The newest 24 values are not shown. Login to preview the entire series.",
      de: "Die neusten 24 Werte werden nicht angezeigt. Melden Sie sich an, um die vollständigen Reihen anzuzeigen.",
    },
    previewWarnPermission: {
      en: "The newest 24 values are not shown. You are logged in but do not have the necessary access rights.",
      de: "Die neusten 24 Werte werden nicht angezeigt. Sie sind zwar angemeldet, haben aber mangelnde Zugriffsrechte.",
    },
    setsWarn: {
      en: "Time series collections are only stored between sessions if you are logged in",
      de: "Zeitreihen-Kollektionen werden nur zwischen Sitzungen gespeichert, wenn Sie angemeldet sind",
    },
    exportWarnLogin: {
      en: "Login to download time series data directly",
      de: "Melden Sie sich an, um Zeitreihen-Daten direkt runterzuladen",
    },
    exportWarnPermission: {
      en: "Direct download of time series data is disabled. You are logged in but do not have the necessary access rights.",
      de: "Der direkte Download von Zeitreihen-Daten ist nicht möglich. Sie sind zwar angemeldet, haben aber mangelnde Zugriffsrechte.",
    },
    linkWarning: {
      en: "The download link contains your personal API key. Sharing it would allow third parties to download data in your name.",
      de: "Der Download-Link enthält Ihren persönlichen API-Key. Ihn zu teilen würde es Dritten erlauben, Daten unter ihrem Nutzer zu beziehen.",
    },
    help: {
      en: "Open documentation in new tab",
      de: "Dokumentation in neuem Tab öffnen",
    },
    settings: { en: "Change settings", de: "Einstellungen ändern" },
    login: {
      en: "Login with your ETH account",
      de: "Mit ETH Account anmelden",
    },
    expandCommonMeta: {
      en: "Show all common metadata",
      de: "Alle gemeinsamen Metadaten einblenden",
    },
    reduceCommonMeta: {
      en: "Show only important common metadata",
      de: "Nur wichtige gemeinsame Metadaten anzeigen",
    },
    selectCommonMeta: {
      en: "Add metadata to filters",
      de: "Metadaten zu Filtern hinzufügen",
    },
    addSelection: { en: "Select metadata", de: "Metadaten selektieren" },
    filterSelection: {
      en: "Filter time series with selected metadata",
      de: "Zeitreihen mit den selektierten Metadaten filtern",
    },
    clearSelection: {
      en: "Clear metadata selection",
      de: "Selektion der Metadaten aufheben",
    },
    addDefaultFilters: {
      en: "Filter time series by default metadata",
      de: "Zeitreihen mit Standard-Metadaten filtern",
    },
    removeCategoryFilter: {
      en: "Stop filtering with metadata of this category",
      de: "Filtern mit den Metadaten dieser Kategorie stoppen",
    },
    typeDot: {
      en: "Insert dot to show the next available chunks in the time series key",
      de: "Punkt einfügen um die nächsten verfügbaren Kürzel im Zeitreihen-Key anzuzeigen",
    },
    applySearch: {
      en: "Filter with search term [Enter]",
      de: "Mit Suchbegriff filtern [Enter]",
    },
  },
  labels: {
    metaSearch: { en: "Metadata Search", de: "Metadaten-Suche" },
    keySearch: { en: "Key Search", de: "Key-Suche" },
    searchView: { en: "Search", de: "Suche" },
    searchFilterTitle: { en: "Search & Filter", de: "Suchen & Filtern" },
    resultsTitle: { en: "Time Series", de: "Zeitreihen" },
    resultsFilteredTitle: {
      en: "Time Series filtered",
      de: "Zeitreihen gefiltert",
    },
    setsTitle: { en: "Time Series Collections", de: "Zeitreihen-Kollektionen" },
    plot: { en: "Preview", de: "Vorschau" },
    copyToClipboard: {
      en: "Copy to clipboard",
      de: "In Zwischenablage kopieren",
    },
    searchPlaceholder: { en: "Search..", de: "Suchen.." },
    tooltipsOn: { en: "Turn off tooltips", de: "Tooltips ausschalten" },
    tooltipsOff: { en: "Turn on tooltips", de: "Tooltips einschalten" },
    keychunksOn: { en: "Hide key chunks", de: "Key-Kürzel ausblenden" },
    keychunksOff: { en: "Show key chunks", de: "Key-Kürzel einblenden" },
    multiSelectOn: {
      en: "Turn off multiselect",
      de: "Multi-Select ausschalten",
    },
    multiSelectOff: {
      en: "Turn on multiselect",
      de: "Multi-Select einschalten",
    },
    filters: { en: "Filters", de: "Filter" },
    thousand: { en: "k", de: " Tsd" },
    million: { en: "m", de: " Mio" },
    billion: { en: "bn", de: " Mrd" },
    loginFailed: { en: "Login failed", de: "Login fehlgeschlagen" },
    filterTerms: {
      en: "Filter time series with ",
      de: "Zeitreihen filtern mit ",
    },
    and: { en: " AND ", de: " UND " },
    chooseLang: { en: "Choose language:", de: "Sprache wählen:" },
    addToNewSet: {
      en: "Add to new collection",
      de: "Hinzufügen zu neuer Kollektion",
    },
    addToSet: { en: "Add to collection ", de: "Hinzufügen zu Kollektion " },
    removeFromSet: {
      en: "Remove from collection ",
      de: "Entfernen von Kollektion ",
    },
    removeFromThis: {
      en: "Remove from this collection",
      de: "Entfernen von dieser Kollektion",
    },
    copyKeyToClipboard: {
      en: "Copy time series key to clipboard",
      de: "Zeitreihen-Key in Zwischenablage kopieren",
    },
    setAsFilter: {
      en: "Set as filter in metadata search",
      de: "Als Filter in der Metadaten-Suche setzen",
    },
    removeAllFromThis: {
      en: "Remove all from this collection",
      de: "Alle entfernen von dieser Kollektion",
    },
    removeAllFromSet: {
      en: "Remove all from collection ",
      de: "Alle entfernen von Kollektion ",
    },
    addAllToSet: {
      en: "Add all to collection ",
      de: "Alle hinzufügen zu Kollektion ",
    },
    addAllToNewSet: {
      en: "Add all to new collection",
      de: "Alle hinzufügen zu neuer Kollektion",
    },
    exportTS: { en: "Time series", de: "Zeitreihen" },
    exportMeta: { en: "Metadata", de: "Metadaten" },
    newSet: { en: "New collection", de: "Neue Kollektion" },
    setNameDialogTitle: { en: "Enter collection name", de: "Namen eingeben" },
    editSetName: {
      en: "Enter new set name",
      de: "Neuen Namen der Kollektion eingeben",
    },
    setAlreadyExistsWarning: {
      en: "Collection with this name exists already!",
      de: "Kollektion mit diesem Namen existiert bereits!",
    },
    warningAPILink: {
      en: "The above link is personal. Do not share it!",
      de: "Der obige Link ist persönlich. Teilen Sie ihn nicht!",
    },
    save: { en: "Save", de: "Speichern" },
    cancel: { en: "Cancel", de: "Abbrechen" },
    yes: { en: "Yes", de: "Ja" },
    no: { en: "No", de: "Nein" },
    deleteSet: {
      en: "Delete this collection?",
      de: "Diese Kollektion löschen?",
    },
    nodata: { en: "No data", de: "Keine Daten" },
    copyKeyToClipboard: {
      en: "Copy time series key to clipboard",
      de: "Zeitreihen-Key in Zwischenablage kopieren",
    },
    copyMetaToClipboard: {
      en: "Copy time series metadata to clipboard",
      de: "Zeitreihen-Metadaten in Zwischenablage kopieren",
    },
    copyKeysToClipboard: {
      en: "Copy all time series keys to clipboard",
      de: "Alle Zeitreihen-Keys in Zwischenablage kopieren",
    },
    gotoWebsite: {
      en: "Go to website",
      de: "Gehe zu Webseite"
    },
    commonMeta: { en: "Common Metadata", de: "Gemeinsame Metadaten" },
    previewWarnLogin: {
      en: "The latest 2 years of data are not shown. Login to preview the entire series.",
      de: "Daten für die letzten 2 Jahre werden nicht angezeigt. Melden Sie sich an, um die vollständigen Reihen anzuzeigen.",
    },
    setsWarn: {
      en: "As a guest your collections are accessible through your guest URL.",
      de: "Als Gast sind Ihre Kollektionen zugänglich über Ihre Gast-URL.",
    },
    exportWarnLogin: {
      en: "Login to download time series data directly.",
      de: "Melden Sie sich an, um Zeitreihen-Daten direkt runterzuladen.",
    },
    exportWarnPermission: {
      en: "Direct download of time series data is not possible. You are logged in but do not have an API key.",
      de: "Der direkte Download von Zeitreihen-Daten ist nicht möglich. Sie sind zwar angemeldet, haben aber keinen API Key.",
    },
    notifAdded: { en: "Added!", de: "Hinzugefügt!" },
    notifCopied: { en: "Copied!", de: "Kopiert!" },
    notifRemoved: { en: "Removed!", de: "Entfernt!" },
    addToPreview: { en: "Add to preview", de: "Zu Vorschau hinzufügen" },
    removeFromPreview: {
      en: "Remove from preview",
      de: "Aus Vorschau entfernen",
    },
    login: { en: "Login", de: "Anmelden" },
    tooManyWarning: {
      en: "Maximum 10'000 series per collection",
      de: "Maximal 10'000 Reihen pro Kollektion",
    },
    collections: { en: "Collections", de: "Kollektionen" },
    addDefaults: { en: "Add Default Filters", de: "Standardfilter hinzufügen" },
    filterSelect: { en: "Filter with Selection", de: "Filtern mit Selektion" },
    settings: { en: "Settings", de: "Einstellungen" },
    dataImportStatus: { en: "Data Import Status", de: "Datenimportstatus" },
  },
};
