import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import { SearchView } from "./SearchView";
import { FilterView } from "./FilterView";
import { SeriesView } from "./SeriesView";
import { ResultsTSButtonMenu } from "./ResultsTSButtonMenu";
import { ResultsButtonMenu } from "./ResultsButtonMenu";
import { ViewTitle } from "./ViewTitle";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { CategoriesView } from "./CategoriesView";
import { ScrollBox } from "./ScrollBox";
import { usePrevious } from "./Various";
import { isEqual, mapValues } from "lodash";
import { useSettings } from "./SettingsProvider";

const Left = styled.div`
  width: 35%;
  height: 100%;
  padding: 0 20px 0 0;
  display: flex;
  flex-direction: column;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const StyledScrollBox = styled(ScrollBox)`
  width: 100%;
  margin-top: 17px;
  display: flex;
  flex-direction: column;
  position: relative;
  /* max-height: ${(props) => `calc(100% - ${props.usedHeight}px - 20px)`}; */
  max-height: ${(props) => `${props.usedHeight}px`};
`;

const StyledSearchView = styled(SearchView)`
  margin-top: 7px;
  width: 100%;
`;

const StyledFilterView = styled(FilterView)`
  /* margin-bottom: ${(props) =>
    !props.keySearch &&
    (props.filterTerms.length !== 0 || props.filters.length !== 0)
      ? `15px`
      : "0px"}; */
  width: 100%;
`;

const StyledCategoriesView = styled(CategoriesView)`
  width: 100%;
  /* margin-top: 15px; */
`;

const StyledSeriesView = styled(SeriesView)`
  width: 65%;
  height: 100%;
  padding: 0 0 0 20px;
`;

export const ResultsView = ({
  onSetFilter,
  results,
  resultCount,
  resultsRange,

  suggestions,
  suggestionsExpanded,
  expandSuggestionsCategory,
  reduceSuggestionsCategory,
  loadMoreSuggestionsCategories,

  categories,
  categoriesExpanded,
  expandCategoriesCategory,
  reduceCategoriesCategory,
  loadMoreCategoriesCategories,

  searchTerms,
  searchMode,
  searchCategory,
  selectCategoryItem,
  selectAll,
  selectCategory,
  removeSearchCategory,
  changeSearchValue,
  searchValue,
  searchHitCount,
  categoriesExpansion,
  suggestionsExpansion,
  setSuggestionMaxHeight,
  setCategoriesMaxHeight,
  filterTerms,
  filters,
  common,
  defaultFilters,
  removeFilter,
  removeFilterTerm,
  clearFilters,
  loadResults,
  suggestedWords,
}) => {
  const [titleHeight, setTitleHeight] = useState(0);
  const [leftHeight, setLeftHeight] = useState(0);
  const [commonMetaExpanded, setCommonMetaExpanded] = useState(false);
  // const [filterHeight, setFilterHeight] = useState(0);

  const {
    settings: { lang },
  } = useSettings();

  const titleRef = useRef();
  const leftRef = useRef();
  const searchViewRef = useRef();
  const filterRef = useRef();
  const scrollRef = useRef();
  const prevCategories = usePrevious(categories);

  useEffect(() => {
    if (
      scrollRef.current &&
      !categoriesExpansion &&
      !isEqual(prevCategories, categories)
    ) {
      scrollRef.current.scrollTop = 0;
    }
  });

  useLayoutEffect(() => {
    const title = titleRef.current;
    if (title && titleHeight !== title.clientHeight) {
      setTitleHeight(title.clientHeight);
    }
    const left = leftRef.current;
    if (left && leftHeight !== left.clientHeight) {
      setLeftHeight(left.clientHeight);
      // setSuggestionMaxHeight(left.clientHeight - 37 - 20)
    }
    // const filter = filterRef.current;
    // if(filter !== null && Math.abs(filterHeight - filter.clientHeight) > 1) {
    //   setFilterHeight(filter.clientHeight);
    //   const bestLeft = left !== null && leftHeight !== left.clientHeight ? left.clientHeight : leftHeight;
    //   const filterMargin = filterTerms.length !== 0 || Object.keys(filters).length !== 0 ? 15 : 0;
    //   setCategoriesMaxHeight(bestLeft - 37 - 20 - filter.clientHeight - filterMargin)
    // }
  });

  const [selectedItems, setSelectedItems] = useState([]);

  const addSelection = (elem) => {
    if (selectedItems.length === 0) {
      var newSelected = [elem];
    } else {
      if (selectedItems[0].category === elem.category) {
        var newSelected = [...selectedItems, elem];
      } else {
        var newSelected = [elem];
      }
    }
    setSelectedItems(newSelected);
  };

  const removeSelection = (elem) => {
    const newSelected = selectedItems.filter((x) => x.id !== elem.id);
    setSelectedItems(newSelected);
  };

  const seriesOptionsButtonRenderer = (series) => (
    <ResultsButtonMenu
      series={series}
      filterTerms={filterTerms}
      filterIds={mapValues(filters, (items) => items.map((item) => item.id))}
      seriesCount={resultCount}
    />
  );

  const optionsButtonRenderer = (
    content,
    hover,
    selectSeries,
    unselectSeries,
    selected,
  ) => (
    <ResultsTSButtonMenu
      content={content}
      hover={hover}
      unselectSeries={unselectSeries}
      selectSeries={selectSeries}
      selected={selected}
      onSetFilter={onSetFilter}
    />
  );

  //  const searchViewHeight = leftHeight - filterHeight - (filterHeight===0 ? 0 : 15) - searchTitleHeight - 15;

  const filterView = (
    <StyledFilterView
      ref={filterRef}
      filterTerms={filterTerms}
      filters={filters}
      common={common}
      defaultFilters={defaultFilters}
      onFilterRemove={removeFilter}
      onFilterTermRemove={removeFilterTerm}
      onClear={clearFilters}
      commonMetaExpanded={commonMetaExpanded}
      setCommonMetaExpanded={setCommonMetaExpanded}
      keySearch={searchMode === "key"}
      onSelectCategoryItem={selectCategoryItem}
      setSelectedItems={setSelectedItems}
    />
  );

  const categoriesView = (
    <StyledCategoriesView
      suggestions={categories}
      categoriesExpanded={categoriesExpanded}
      onExpandCategory={expandCategoriesCategory}
      onReduceCategory={reduceCategoriesCategory}
      searchMode={searchMode}
      onSelectCategoryItem={selectCategoryItem}
      expansion={categoriesExpansion}
      selectedItems={selectedItems}
      addSelection={addSelection}
      removeSelection={removeSelection}
      setSelectedItems={setSelectedItems}
    />
  );

  const searchView = (
    <StyledSearchView
      ref={searchViewRef}
      maxHeight={leftHeight - 7 - titleHeight}
      suggestions={suggestions}
      categoriesExpanded={suggestionsExpanded}
      onExpandCategory={expandSuggestionsCategory}
      onReduceCategory={reduceSuggestionsCategory}
      onLoad={loadMoreSuggestionsCategories}
      searchTerms={searchTerms}
      resultCount={resultCount}
      searchMode={searchMode}
      searchCategory={searchCategory}
      onSelectCategoryItem={selectCategoryItem}
      onSelectAll={selectAll}
      onSelectCategory={selectCategory}
      onChangeSearchValue={changeSearchValue}
      onRemoveSearchCategory={removeSearchCategory}
      onSearchChange={changeSearchValue}
      searchValue={searchValue}
      searchHitCount={searchHitCount}
      suggestedWords={suggestedWords}
      expansion={suggestionsExpansion}
      selectedItems={selectedItems}
      addSelection={addSelection}
      removeSelection={removeSelection}
      setSelectedItems={setSelectedItems}
    />
  );

  //const searchFilterView = searchMode === 'meta' ? [filterDiv, searchDiv] : [searchDiv, filterDiv];
  const title =
    Object.keys(filters).length !== 0 || filterTerms.length !== 0
      ? ui.labels.resultsFilteredTitle[lang]
      : ui.labels.resultsTitle[lang];

  return (
    <>
      <Left ref={leftRef}>
        <ViewTitle label={ui.labels.searchFilterTitle[lang]} ref={titleRef} />
        {searchView}
        {searchMode === "meta" ? (
          <StyledScrollBox
            scrollRef={scrollRef}
            onScrollEnd={loadMoreCategoriesCategories}
            usedHeight={leftHeight - titleHeight - 37 - 7 - 17}
          >
            {filterView}
            {categoriesView}
          </StyledScrollBox>
        ) : (
          <>
            {/* {resultCount > 1 && <TypeDotLabel><i>{ui.labels.typeDot[lang]}</i></TypeDotLabel>} */}
            <FlexGrow />
            {filterView}
          </>
        )}
      </Left>
      <StyledSeriesView
        series={results}
        seriesCount={resultCount}
        title={title}
        optionsButtonRenderer={optionsButtonRenderer}
        seriesOptionsButtonRenderer={seriesOptionsButtonRenderer}
        resetScrollOnChange={
          (searchMode === "key" && !suggestionsExpansion) ||
          (searchMode === "meta" && !categoriesExpansion)
        }
        loadRange={loadResults}
        range={resultsRange}
        highlightTerms={filterTerms}
      />
    </>
  );
};
