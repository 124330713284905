import React, { useState, useRef } from "react";
import { ButtonMenu } from "./ButtonMenu";
import { map } from "lodash";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { PlaylistAdd } from "@mui/icons-material";
import { CommonIconButton, MenuItemButton } from "./Various";
import { copyToClipboard, upperCaseFirst } from "../utils";
import { Notification } from "./Various";
import { useUserData } from "./UserDataProvider";
import { useSettings } from "./SettingsProvider";

const MenuFlex = styled.div`
  display: flex;
  flex-direction: column;
`;

const TargetSetLabel = styled.span`
  font-weight: 700;
  font-style: italic;
`;

export const SetsTSButtonMenu = ({
  content,
  selectSeries,
  unselectSeries,
  selected,
  onSetFilter,
}) => {
  const {
    settings: { lang },
  } = useSettings();
  const { sets, activeSet, setFunctions: setFunc } = useUserData();
  const [show, setShow] = useState(false);
  const [showNotif, setShowNotif] = useState(false);

  var notification = useRef("");

  const notify = (text) => {
    notification.current = text;
    setShowNotif(true);
    setTimeout(() => setShowNotif(false), 500);
  };

  const setNames = Object.keys(sets).filter((x) => x !== activeSet);
  var items = map(setNames, (setName) => (
    <MenuItemButton
      key={setName}
      onMouseDown={(event) => {
        setFunc.addToSet(setName, [content.key]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <div>
        <span>{ui.labels.addToSet[lang]}</span>
        <TargetSetLabel>{setName}</TargetSetLabel>
      </div>
    </MenuItemButton>
  ));

  const activeItem = (
    <MenuItemButton
      key={"remove-active-set"}
      onMouseDown={(event) => {
        setFunc.removeFromSet(activeSet, [content.key]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <span>{ui.labels.removeFromThis[lang]}</span>
    </MenuItemButton>
  );

  items = [activeItem, ...items];

  const button = (
    <CommonIconButton
      tooltip={ui.tooltips.tsMenu[lang]}
      ariaPressed={show}
      icon={<PlaylistAdd />}
      onMouseDown={(event) => {
        setShow(true);
        event.stopPropagation();
      }}
    />
  );

  const buttonPreview = (
    <MenuItemButton
      dividerBottom
      onMouseDown={(event) => {
        setShow(false);
        selected ? unselectSeries(content.key) : selectSeries(content.key);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      {selected
        ? ui.labels.removeFromPreview[lang]
        : ui.labels.addToPreview[lang]}
    </MenuItemButton>
  );

  const filterButton = (
    <MenuItemButton
      dividerTop
      onMouseDown={(event) => {
        onSetFilter(content);
        setShow(false);
        event.stopPropagation();
      }}
    >
      <span>{ui.labels.setAsFilter[lang]}</span>
    </MenuItemButton>
  );

  const buttonCopyKey = (
    <MenuItemButton
      dividerTop
      onMouseDown={(event) => {
        copyToClipboard(content.key);
        notify(ui.labels.notifCopied[lang]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      {ui.labels.copyKeyToClipboard[lang]}
    </MenuItemButton>
  );

  const metaText = content.meta
    .map(
      (meta) =>
        upperCaseFirst(meta.category) + ": " + upperCaseFirst(meta.value),
    )
    .join("\n");

  const buttonCopyMetadata = (
    <MenuItemButton
      dividerTop
      onMouseDown={(event) => {
        copyToClipboard(metaText);
        notify(ui.labels.notifCopied[lang]);
        setShow(false);
        event.stopPropagation();
      }}
    >
      {ui.labels.copyMetaToClipboard[lang]}
    </MenuItemButton>
  );

  const url = content.meta.find(x => x.category=="URL")
  const buttonWebsite = url ?
    <MenuItemButton
      dividerTop
      log="results_ts_options_website"
      onMouseDown={(event) => {
        window.open(url.value);
        setShow(false);
        event.stopPropagation();
      }}>
    {ui.labels.gotoWebsite[lang]}
  </MenuItemButton> : null;

  return (
    <>
      <ButtonMenu button={button} show={show} onExit={() => setShow(false)}>
        <MenuFlex>
          {buttonPreview}
          {items}
          {buttonCopyKey}
          {buttonCopyMetadata}
          {buttonWebsite}
          {filterButton}
        </MenuFlex>
      </ButtonMenu>
      <Notification show={showNotif}>{notification.current}</Notification>
    </>
  );
};
