import React, { forwardRef, useRef, useContext, useEffect } from 'react'
import { ui } from '../constants'
import styled from "@emotion/styled"
import { Tooltip } from './Tooltip'
import { GlobalContext } from './GlobalContext';

const IconChildrenSpace = styled.div`
  display: inline-block;
  width: ${props => props.space};
`

const StyledButton = styled.button`
  all: unset;
  box-sizing: border-box; 
  padding: ${props => props.padding};
  justify-content: ${props => props.justifyContent};
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  font-weight: 700;
  user-select: none;
  transition: background-color ${ui.button.colorTransitionTime/1000}s, color ${ui.button.colorTransitionTime/1000}s;
  pointer-events: auto;
  cursor: pointer;
  background-color: ${ui.button.backgroundColor};
  color: ${ui.button.color};
  &:disabled {
    pointer-events: none;
    cursor: default;
    background-color: ${ui.button.backgroundColorDisabled};
    color: ${ui.button.colorDisabled};
  }
  &:hover {
    background-color: ${ui.button.backgroundColorHover};
    color: ${ui.button.colorHover};
  }
  &:active {
    background-color: ${ui.button.backgroundColorActive};
    color: ${ui.button.colorActive};
  }
  &[aria-pressed='true'] {
    background-color: ${ui.button.backgroundColorAriaPressed};
    color: ${ui.button.colorAriaPressed};
  }
`

export const Button = forwardRef(({
  iconPosition = 'left',
  children,
  tooltip,
  log,
  icon,
  iconChildrenSpace = '0.3em',
  ariaPressed,
  ...other}, ref) =>
{
  const localRef = useRef(null)

  const global = useContext(GlobalContext)

  useEffect(() => {
    if(ref) {
      if(typeof ref === "function") {
        // handle callback refs
        ref(localRef.current);
      } else {
        // handle object refs
        ref.current = localRef.current;
      }
    }
    if(localRef && localRef.current && log) {
      localRef.current.addEventListener('mousedown', logFetch)
    }
    return () => {
      if(localRef && localRef.current && log) {
        localRef.current.removeEventListener('mousedown', logFetch)
      }  
    }
  },[])

  const logFetch = () => {
    fetch(`${process.env.REACT_APP_API_URL}/sets/log`, {
      method: 'POST',
      body: JSON.stringify({ username: global.username, id: log }),
      headers: {'Content-Type': 'application/json'}
    })
    .then(response => {})
    .catch(error => {});
  }

  var justifyContent = 'center'
  var padding = '0'
  var content;

  if(children && !icon) {
    padding = '0.5em 0.6em 0.5em 0.6em'
    content = children
  } else if(!children && icon) {
    padding = '5px'
    content = icon
  } else {
    if(iconPosition === 'left') {
      // padding = '0.2em 0.6em 0.2em 0.2em'
      padding = '0.3em 0.7em 0.3em 0.3em'
      justifyContent = 'flex-start'
      content = 
        <>
          {icon}
          <IconChildrenSpace space={iconChildrenSpace}/>
          {children}
        </>
    }
    else {
      padding = '0.2em 0.2em 0.2em 0.6em'
      justifyContent = 'flex-end'
      content = 
        <>
          {children}
          <IconChildrenSpace space={iconChildrenSpace}/>
          {icon}
        </>
    }
  }

  var button =
    <StyledButton
      padding={padding}
      justifyContent={justifyContent}
      aria-pressed={ariaPressed}
      ref={localRef}
      {...other}>
        {content}
    </StyledButton>

  if(tooltip) {
    button = <Tooltip text={tooltip} content={button} contentRef={localRef}/>
  }
  
  return button;
})