import React from "react";
import { Dialog } from "./Dialog";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { useSettings } from "./SettingsProvider";

const AlreadyExistsWarning = styled.div`
  font-weight: 700;
  margin: 10px 0 2px 0;
  color: ${ui.warnColor};
`;

const Input = styled.input`
  padding: 0.4em 0.5em 0.4em 0.5em;
  border: 1px solid;
  width: 100%;
  min-width: 250px;
`;

export const SetNameDialog = ({
  value,
  show,
  alreadyExists,
  onChange,
  onCancel,
  onSave,
}) => {
  const {
    settings: { lang },
  } = useSettings();
  return (
    <Dialog
      buttonNames={{ ok: ui.labels.save[lang], cancel: ui.labels.cancel[lang] }}
      show={show}
      title={ui.labels.setNameDialogTitle[lang]}
      contentHeight={200}
      onCancel={onCancel}
      onSave={onSave}
      okEnabled={!alreadyExists && value !== ""}
    >
      <Input
        value={value}
        autoFocus={true}
        onMouseDown={(event) => event.stopPropagation()}
        onChange={(event) => onChange(event.target.value)}
      />
      {alreadyExists && (
        <AlreadyExistsWarning>
          {ui.labels.setAlreadyExistsWarning[lang]}
        </AlreadyExistsWarning>
      )}
    </Dialog>
  );
};
