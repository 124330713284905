import React from "react";
import styled from "@emotion/styled";
import { ui } from "../constants";
import { upperCaseFirst, checkMissing } from "../utils";
import Highlighter from "react-highlight-words";
import { reduce } from "lodash";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { CommonIconButton } from "./Various";

const FlexGrow = styled.div`
  flex-grow: 1;
`;

const ExpandButton = styled(CommonIconButton)`
  padding-top: 0px;
  padding-bottom: 0px;
  margin-top: 5px;
  margin-bottom: 1px;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
`;

const TSInfo = styled.div`
  flex-grow: 1;
  padding-left: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
`;

const MetaItems = styled.div`
  margin-top: 0.3em;
`;

const TSKey = styled.span`
  font-weight: 700;
  font-size: 0.9em;
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 0px;
  padding-bottom: 0px;
  align-content: right;
`;

const MetaKeyChunkHighlighter = styled(Highlighter)`
  margin-left: 1em;
  font-size: 1em;
  color: grey;
`;

const MetaValueHighlighter = styled(Highlighter)`
  margin-left: 0.3em;
  color: black;
  font-size: 1em;
`;

const MetaCategoryHighlighter = styled(Highlighter)`
  font-weight: 400;
  font-size: 1em;
`;

const MetaValue = styled.span`
  margin-left: 0.3em;
  font-size: 1em;
  color: ${(props) =>
    props.highlight ? ui.filterHighlightStyle.color : "black"};
`;

const MetaCategory = styled.span`
  font-weight: 400;
  font-size: 1em;
  color: ${(props) =>
    props.highlight ? ui.filterHighlightStyle.color : "black"};
`;

const MetaItem = styled.div`
  display: block;
  padding: 0.08em 0 0.08em 0;
`;

export const createTSContentRenderer =
  (
    highlightTerms,
    lang,
    showKeychunks,
    unselectSeries,
    selectSeries,
    optionsButtonRenderer,
  ) =>
  (key, content, selected, expanded, hover, toggleExpansion) => {
    var cats = expanded
      ? content.meta
      : content.meta.filter(
          (x) => x.filter === 0 && x.common === 0 && x.default === 0,
        );
    cats = cats.filter(meta => meta.category !== "URL");
    const items = cats.map((meta) => (
      <MetaItem key={`${meta.category}-${meta.id}`}>
        {meta.filter !== 0 ? (
          <>
            <MetaCategory highlight={!meta.common}>
              {upperCaseFirst(meta.category) + ":"}
            </MetaCategory>
            <MetaValue highlight={!meta.common}>
              {upperCaseFirst(meta.value)}
            </MetaValue>
          </>
        ) : (
          <>
            <MetaCategoryHighlighter
              textToHighlight={upperCaseFirst(meta.category) + ":"}
              highlightStyle={ui.filterHighlightStyle}
              searchWords={highlightTerms}
            />
            <MetaValueHighlighter
              textToHighlight={upperCaseFirst(meta.value)}
              highlightStyle={ui.filterHighlightStyle}
              searchWords={highlightTerms}
            />
          </>
        )}
        {meta.keyChunk !== "" &&
          (showKeychunks ||
            checkMissing(meta.value, lang) ||
            reduce(
              highlightTerms,
              (match, term) =>
                match || meta.keyChunk.match(new RegExp(term, "i")),
              false,
            )) && (
            <MetaKeyChunkHighlighter
              textToHighlight={"[" + meta.keyChunk + "]"}
              highlightStyle={ui.filterHighlightStyle}
              searchWords={highlightTerms}
            />
          )}
      </MetaItem>
    ));

    return (
      <>
        <TSInfo>
          <TSKey>{content.key}</TSKey>
          <MetaItems>{items}</MetaItems>
        </TSInfo>
        <Buttons>
          {optionsButtonRenderer(
            content,
            hover,
            selectSeries,
            unselectSeries,
            selected,
          )}
          {/* <PlotButton
            tooltip={selected ? ui.tooltips.removeFromPlot[lang] : ui.tooltips.addToPlot[lang]}
            hover={true}
            icon={selected ? <VisibilityOff/> : <Visibility/>}
            onMouseDown={selected ? event => { unselectSeries(key); event.stopPropagation(); } :
                                    event => { selectSeries(key); event.stopPropagation(); }}/> */}
          <FlexGrow />
          <ExpandButton
            visible={expanded || items.length < content.meta.length}
            tooltip={
              expanded
                ? ui.tooltips.reduceMeta[lang]
                : ui.tooltips.expandMeta[lang]
            }
            log="results_expand_meta"
            icon={expanded ? <ExpandLess /> : <ExpandMore />}
            onMouseDown={(event) => {
              toggleExpansion(key);
              event.stopPropagation();
            }}
          />
        </Buttons>
      </>
    );
  };
