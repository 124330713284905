import React, { useState } from "react";
import { Dialog } from "./Dialog";
import { SetNameDialog } from "./SetNameDialog";
import styled from "@emotion/styled";
import { ui } from "../constants";
import { SetButtonMenu } from "./SetButtonMenu";
import { CommonIconButton } from "./Various";
import { Edit, Clear } from "@mui/icons-material";
import { useUserData } from "./UserDataProvider";
import { useSettings } from "./SettingsProvider";

const Count = styled.span`
  font-weight: 400;
  margin-left: 0.5em;
  font-style: 1.1em;
`;

const SetName = styled.span`
  font-weight: 700;
  font-style: 1.1em;
`;

const Text = styled.div`
  padding: 0 0 0 1em;
  display: block;
  width: 100%;
  text-align: left;
`;

const Main = styled.div`
  display: flex;
  align-items: center;
  transition: background-color 0.15s;
  background-color: ${(props) => props.backgroundColor};
  cursor: pointer;
`;

export const SetListItem = ({ setName, selected }) => {
  const {
    settings: { lang },
  } = useSettings();
  const { sets, setFunctions: setFunc } = useUserData();
  const [hover, setHover] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [edit, setEdit] = useState("");
  const [showEdit, setShowEdit] = useState(false);

  const onEdit = (event, setName) => {
    setEdit(setName);
    setShowEdit(true);
    event.preventDefault();
    event.stopPropagation();
  };

  const onDone = () => {
    setFunc.changeSetName(setName, edit);
    setEdit("");
    setShowEdit(false);
    //event.stopPropagation();
  };

  var backgroundColor = "white";
  backgroundColor = hover ? ui.stdColor10 : backgroundColor;
  backgroundColor = selected ? ui.stdColor30 : backgroundColor;

  return (
    <Main
      backgroundColor={backgroundColor}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onMouseDown={() => setFunc.selectSet(setName)}
    >
      <Text>
        <SetName>{setName}</SetName>
        <Count>{"(" + sets[setName] + ")"}</Count>
      </Text>
      <SetNameDialog
        show={showEdit}
        value={edit}
        alreadyExists={edit !== setName && Object.keys(sets).includes(edit)}
        onCancel={() => {
          setEdit("");
          setShowEdit(false);
        }}
        onSave={onDone}
        onChange={(value) => setEdit(value)}
      />
      {sets[setName] > 0 && <SetButtonMenu setName={setName} />}
      <CommonIconButton
        tooltip={ui.tooltips.editSet[lang]}
        icon={<Edit />}
        onMouseDown={(event) => onEdit(event, setName)}
      />
      <CommonIconButton
        tooltip={ui.tooltips.deleteSet[lang]}
        icon={<Clear />}
        onMouseDown={(event) => {
          setDeleteDialog(true);
          event.stopPropagation();
        }}
      />
      <Dialog
        show={deleteDialog}
        title={ui.labels.deleteSet[lang]}
        buttonNames={{ ok: ui.labels.yes[lang], cancel: ui.labels.no[lang] }}
        onCancel={() => setDeleteDialog(false)}
        onSave={() => {
          setFunc.removeSet(setName);
          setDeleteDialog(false);
        }}
      ></Dialog>
    </Main>
  );
};
