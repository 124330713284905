import React, { useRef, useEffect } from "react";
import { ui, colors } from "../constants";
import { Tooltip } from "./Tooltip";
import styled from "@emotion/styled";
import { ErrorOutline } from "@mui/icons-material";
import { Button } from "./Button";
import { Transition } from "react-transition-group";

//export const Icon = ({name, ...other}) => <i className='material-icons' {...other}>{name}</i>

const WarningIconStyled = styled(ErrorOutline)`
  padding: 0px;
  transition: color 0.2s;
  color: ${ui.stdColor100};
  &:hover {
    color: ${ui.stdColor100};
  }
`;

export const WarningIcon = ({ tooltip, ...other }) => (
  <Tooltip text={tooltip} delay={200} ignoreGlobalSetting={true}>
    <WarningIconStyled {...other} />
  </Tooltip>
);

export const CommonIconButton = styled(Button)`
  background-color: transparent;
  color: ${colors.grey80};
  &:hover {
    background-color: ${colors.grey10};
    color: ${colors.grey100};
  }
  &:active {
    background-color: transparent;
    color: ${colors.grey100};
  }
  &:disabled {
    background-color: transparent;
    color: ${colors.grey60};
  }
  &[aria-pressed="true"] {
    background-color: transparent;
    color: ${colors.grey100};
  }
`;

export const MenuItemButton = styled(Button)`
  padding: 0.7em 1em 0.7em 1em;
  justify-content: flex-start;
  font-size: 1.1em;
  font-weight: 400;
  background-color: ${ui.menu.backgroundColor};
  border-top: ${(props) =>
    props.dividerTop ? `1px solid ${ui.dividerColor}` : "none"};
  border-bottom: ${(props) =>
    props.dividerBottom ? `1px solid ${ui.dividerColor}` : "none"};
  &:hover {
    background-color: ${ui.menu.backgroundColorHover};
  }
  &:active {
    background-color: ${ui.menu.backgroundColorActive};
  }
  &:disabled {
    background-color: ${ui.menu.backgroundColor};
  }
`;

const OnOff = styled.div`
  border: 1px solid ${(props) => (props.on ? ui.stdColor100 : "black")};
  font-weight: 700;
  color: ${(props) => (props.on ? "white" : "black")};
  background-color: ${(props) => (props.on ? ui.stdColor100 : "transparent")};
  margin-left: 15px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 22px;
`;

const ToggleContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const FlexGrow = styled.div`
  flex-grow: 1;
`;

export const ToggleButton = ({ toggled, children, ...other }) => (
  <MenuItemButton {...other}>
    <ToggleContent>
      {children}
      <FlexGrow />
      <OnOff on={toggled}>{toggled ? "ON" : "OFF"}</OnOff>
    </ToggleContent>
  </MenuItemButton>
);

export const usePrevious = (value) => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
};

// export const Notification = ({children}) =>
//   <Transition in={show} timeout={1000} unmountOnExit={true}>
//     {state =>
//     <StyledNotification state={state}>
//       {children}
//     </StyledNotification>}
//   </Transition>

const transitionOpacity = { entering: 1, entered: 1, exiting: 0, exited: 0 };

const StyledNotification = styled.div`
  position: fixed;
  /* visibility: hidden; */
  right: 20px;
  bottom: 20px;
  /* transform: translate(-50%, -50%); */
  padding: 20px;
  box-shadow: 0px 1px 5px 0px ${colors.grey70};
  outline: 1px solid ${colors.grey50};
  z-index: 99;
  background-color: white;
  font-weight: 700;
  font-size: 1.1em;
  background-color: ${ui.stdColor20};
  transition: opacity 0.25s;
  opacity: ${(props) => ui.transitionOpacity[props.state]};
`;
export const Notification = ({ show, children }) => (
  <Transition in={show} timeout={150} unmountOnExit={true}>
    {(state) => (
      <StyledNotification state={state}>{children}</StyledNotification>
    )}
  </Transition>
);
