import React, { useRef, useState, useEffect, useLayoutEffect, cloneElement } from 'react';
import { ui } from '../constants';
import { Transition } from 'react-transition-group';
import styled from "@emotion/styled";

const Menu = styled.div`
  display: flex;
  white-space: nowrap;
  overflow-x: hidden;
  transition: opacity 0.1s;
  position: fixed;
  box-shadow: 0 0 5px 0 grey;
  z-index: 2;
  opacity: ${props => ui.transitionOpacity[props.state]};
  left: ${props => props.left}px;
  top: ${props => props.top}px;
`

const Measure = styled.div`
  visibility: hidden;
  position: fixed;
  top: 0;
  left: 0;
`

export const ButtonMenu = ({
  button,
  onExit,
  children,
  show,
  horizontal
}) => {

  const [buttonRect, setButtonRect] = useState({ left: 0, top: 0, height: 0, width: 0 });
  const [viewport, setViewport] = useState({ height: 0, width: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const buttonRef = useRef(null);  
  const contentRef = useRef(null);
  const divRef = useRef(null);
  const menuRef = useRef(null);

  const resize = () => {
    setViewport({ width: window.innerWidth, height: window.innerHeight });
  }

  useLayoutEffect(() => {
    const content = contentRef.current;
    if(content && (size.width !== content.clientWidth || size.height !== content.clientHeight)) {
      setSize({ width: content.clientWidth, height: content.clientHeight});
    }

    if(buttonRef.current) {
      const clientRect = buttonRef.current.getBoundingClientRect();
      if(buttonRect.left !== clientRect.left || buttonRect.top !== clientRect.top) {
        setButtonRect({ left: clientRect.left, top: clientRect.top, height: clientRect.height, width: clientRect.width });
      }
    }
  })

  const mousedown = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      onExit()
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resize)
    window.addEventListener('mousedown', mousedown, true)
    resize();
    return () => {
      window.removeEventListener('resize', resize)
      window.removeEventListener('mousedown', mousedown, true)
    }
  },[])

  const buttonClone = cloneElement(button, { ref: buttonRef });
  var menuLeft;
  var menuTop;

  if(horizontal) {
    menuLeft = buttonRect.left + buttonRect.width/2 - size.width/2;
    //var menuLeft = buttonRect.left;
    menuTop = buttonRect.top + buttonRect.height;
    if(menuLeft + size.width > viewport.width - 25) {
      menuLeft = viewport.width - 25 - size.width;
    }
    if(menuTop + size.height > viewport.height - 25) {
      menuTop = buttonRect.top - size.height;
    }        
  } else {
    menuLeft = buttonRect.left + buttonRect.width;
  //     var menuLeft = buttonRect.left;
  //      var menuTop = buttonRect.top + buttonRect.height/2 - size.height/2;
    menuTop = buttonRect.top;
    if(menuLeft + size.width > viewport.width - 25) {
      menuLeft = buttonRect.left - size.width;
    }
    if(menuTop + size.height > viewport.height - 25) {
      menuTop = viewport.height - 25 - size.height;
    }  
  }
    
  const dispatchMouseout = () => divRef.current.dispatchEvent(new MouseEvent('mouseout', {view: window, bubbles: true, cancelable: true}));
  //       <div style={{position: 'fixed', left: 0, top: 0, width: '100%', height: '100%', zIndex: 1}} onMouseDown={event => { onExit(); event.stopPropagation(); }}/>
    //const maxWidth = { entering: size.width, entered: size.width, exiting: 0, exited: 0 };
  return(
    <div ref={divRef}>
      {buttonClone}
      {show && <Measure ref={contentRef}>{children}</Measure>}
      {/* <Transition in={show} timeout={100} unmountOnExit={true} onExited={dispatchMouseout} > */}
      <Transition in={show} timeout={100} unmountOnExit={true} onExited={dispatchMouseout}>
        {state => 
        <>
          {/* <Screen onMouseDown={event => { onExit(); event.stopPropagation(); }}/> */}
          {/* <Screen onMouseDown={event => { onExit();  }}/> */}
          <Menu state={state} left={menuLeft} top={menuTop} ref={menuRef}>
            {children}
          </Menu>
        </>}
      </Transition>
    </div>)
}