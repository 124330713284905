import React, { useState, useLayoutEffect, useEffect, useRef } from "react";
import { ui } from "../constants";
import { Transition } from "react-transition-group";
import styled from "@emotion/styled";
import { useSettings } from "./SettingsProvider";

// Styles
const Content = styled.div``;

const StyledTooltip = styled.div`
  padding: 0.4em 0.4em 0.4em 0.4em;
  font-weight: 400;
  font-size: 1.1em;
  position: fixed;
`;

const TooltipShow = styled(StyledTooltip)`
  color: black;
  background-color: white;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  width: ${(props) => props.width}px;
  z-index: 99;
  text-align: center;
  box-shadow: 0px 0px 2px 0 black;
  pointer-events: none;
  transition: opacity 0.15s;
  opacity: ${(props) => ui.transitionOpacity[props.transitionState]};
`;

const TooltipMeasure = styled(StyledTooltip)`
  visibility: hidden;
  top: 0;
  left: 0;
`;

export const Tooltip = ({
  text,
  delay = 0,
  ignoreGlobalSetting = false,
  content,
  contentRef,
  children,
  ...other
}) => {
  const [show, setShow] = useState(false);
  const [viewport, setViewport] = useState({ height: 0, width: 0 });
  const [size, setSize] = useState({ width: 0, height: 0 });
  const [pos, setPos] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef(null);
  const tooltipTimeout = useRef(null);
  const mouseX = useRef(0);
  const mouseY = useRef(0);

  const { settings } = useSettings();

  const resize = () => {
    setViewport({ width: window.innerWidth, height: window.innerHeight });
  };

  const mouseEnter = (event) => {
    tooltipTimeout.current = setTimeout(setTooltip, delay);
  };

  const mouseLeave = (event) => {
    setShow(false);
    clearTimeout(tooltipTimeout.current);
  };

  const mouseDown = (event) => {
    setShow(false);
    clearTimeout(tooltipTimeout.current);
  };

  const mouseMove = (event) => {
    mouseX.current = event.pageX;
    mouseY.current = event.pageY;
  };

  const setTooltip = () => {
    setShow(true);
    setPos({ x: mouseX.current, y: mouseY.current });
  };

  useLayoutEffect(() => {
    const tooltip = tooltipRef.current;
    if (
      tooltip &&
      (size.width !== tooltip.clientWidth ||
        size.height !== tooltip.clientHeight)
    ) {
      setSize({ width: tooltip.clientWidth, height: tooltip.clientHeight });
    }
  });

  useEffect(() => {
    window.addEventListener("resize", resize);
    window.addEventListener("mousemove", mouseMove);
    if (contentRef && contentRef.current) {
      contentRef.current.addEventListener("mouseenter", mouseEnter);
      contentRef.current.addEventListener("mouseleave", mouseLeave);
      contentRef.current.addEventListener("mousedown", mouseDown);
    }

    resize();

    return () => {
      window.removeEventListener("resize", resize);
      window.removeEventListener("mousemove", mouseMove);
      if (contentRef && contentRef.current) {
        contentRef.current.removeEventListener("mouseenter", mouseEnter);
        contentRef.current.removeEventListener("mouseleave", mouseLeave);
        contentRef.current.removeEventListener("mousedown", mouseDown);
      }
    };
  }, []);

  var tooltipLeft = pos.x - 0.5 * size.width;
  var tooltipTop = pos.y + 30;
  const minBorderDist = 15;

  if (tooltipLeft < minBorderDist) {
    tooltipLeft = minBorderDist;
  } else if (tooltipLeft + size.width > viewport.width - minBorderDist) {
    tooltipLeft = viewport.width - minBorderDist - size.width;
  }

  if (viewport.height - tooltipTop - size.height < minBorderDist) {
    tooltipTop = pos.y - 10 - size.height;
  }

  const enabled = show && (ignoreGlobalSetting || settings.showTooltips);

  content = content || (
    <Content
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onMouseDown={mouseDown}
      {...other}
    >
      {children}
    </Content>
  );

  return (
    <>
      <>
        {enabled && <TooltipMeasure ref={tooltipRef}>{text}</TooltipMeasure>}
        <Transition in={enabled} timeout={100} unmountOnExit={true}>
          {(state) => (
            <TooltipShow
              transitionState={state}
              left={tooltipLeft}
              top={tooltipTop}
              width={size.width + 1}
            >
              {text}
            </TooltipShow>
          )}
        </Transition>
      </>
      {content}
    </>
  );
};
