import React from "react";
import { Button } from "./Button";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { ToggleButton } from "./Various";
import { useSettings } from "./SettingsProvider";

const MenuFlex = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${ui.menu.backgroundColor};
`;

const LanguageFlex = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 1em;
`;

const ChooseLanguageLabel = styled.div`
  font-weight: 400;
  font-size: 1.1em;
  padding: 0.7em 1em 0.7em 1em;
`;

const LanguageButtons = styled.div`
  margin: 0 1em 0 1em;
  display: inline-flex;
  flex-direction: column;
  overflow: hidden;
`;

const EnglishButton = styled(Button)`
  justify-content: flex-start;
`;

const DeutschButton = styled(Button)`
  justify-content: flex-start;
`;

export const SettingsMenu = () => {
  const { settings, changeSetting } = useSettings();

  const toggleShowTooltips = () => {
    changeSetting("showTooltips", !settings.showTooltips);
  };

  const toggleShowKeychunks = () => {
    changeSetting("showKeychunks", !settings.showKeychunks);
  };

  const toggleMultiSelect = () => {
    changeSetting("multiSelect", !settings.multiSelect);
  };

  const setLanguage = (lang) => {
    changeSetting("lang", lang);
  };

  return (
    <MenuFlex>
      <LanguageFlex>
        <ChooseLanguageLabel>
          {ui.labels.chooseLang[settings.lang]}
        </ChooseLanguageLabel>
        <LanguageButtons>
          <DeutschButton
            ariaPressed={settings.lang === "de"}
            onMouseDown={() => setLanguage("de")}
          >
            Deutsch
          </DeutschButton>
          <EnglishButton
            ariaPressed={settings.lang === "en"}
            log="settings_english"
            onMouseDown={() => setLanguage("en")}
          >
            English
          </EnglishButton>
        </LanguageButtons>
      </LanguageFlex>
      <ToggleButton
        dividerTop
        log="settings_toggle_keychunks"
        toggled={settings.showKeychunks}
        onMouseDown={toggleShowKeychunks}
      >
        Key chunks
        {/* {settings.showKeychunks ? ui.labels.keychunksOn[settings.lang] : ui.labels.keychunksOff[settings.lang]} */}
      </ToggleButton>
      <ToggleButton
        dividerTop
        toggled={settings.showTooltips}
        log="settings_toggle_tooltips"
        onMouseDown={toggleShowTooltips}
      >
        Tooltips
        {/* {settings.showTooltips ? ui.labels.tooltipsOn[settings.lang] : ui.labels.tooltipsOff[settings.lang]} */}
      </ToggleButton>
      <ToggleButton
        dividerTop
        log="settings_toggle_multiselect"
        toggled={settings.multiSelect}
        onMouseDown={toggleMultiSelect}
      >
        Multiselect
        {/* {settings.multiSelect ? ui.labels.multiSelectOn[settings.lang] : ui.labels.multiSelectOff[settings.lang]} */}
      </ToggleButton>
    </MenuFlex>
  );
};
