import React, { forwardRef, memo, useEffect, useRef, useState } from "react";
import { reduce, isEqual } from "lodash";
import { ui, colors } from "../constants";
import { SuggestionsView } from "./SuggestionsView";
import styled from "@emotion/styled";
import { Clear, Done, NavigateNext } from "@mui/icons-material";
import { CommonIconButton, usePrevious } from "./Various";
import { useSettings } from "./SettingsProvider";

const AA = styled.div`
  position: absolute;
  box-shadow: none;
  top: 0px;
  box-shadow: ${(props) =>
    props.searchFieldFocus ? `0px 2px 7px 0px ${colors.grey50}` : "none"};
  outline: 1px solid ${colors.grey30};
  width: 100%;
  z-index: 10;
`;

const Main = styled.div`
  height: 37px;
  position: relative;
  display: flex;
  width: 100%;
`;

const StyledSuggestionsView = styled(SuggestionsView)`
  width: 100%;
  box-shadow: ${ui.boxShadow};
  max-height: ${(props) => props.maxHeight}px;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  font-size: 1.1em;
  padding: none;
  margin-left: 10px;
  background-color: white;
`;

const SearchField = styled.div`
  display: flex;
  background-color: white;
  align-items: stretch;
  height: 37px;
  transition: border-color 0.2s;
  overflow: hidden;
  border: none;
  outline: ${(props) =>
    props.searchFieldFocus ? "none" : `1px solid ${colors.grey30}`};

  /* border: ${(props) =>
    props.focus ? `1px solid ${colors.grey80}` : `1px solid ${colors.grey80}`};
  outline: ${(props) =>
    props.focus ? `1px solid ${colors.grey80}` : "none"}; */
`;

export const SearchView = forwardRef(
    (
      {
        filterView,
        suggestions,
        categoriesExpanded,
        searchTerms,
        searchCategory,
        resultCount,
        onExpandCategory,
        onReduceCategory,
        searchMode,
        onSelectCategoryItem,
        onSelectAll,
        onSelectCategory,
        onChangeSearchValue,
        onRemoveSearchCategory,
        searchValue,
        onLoad,
        searchHitCount,
        expansion,
        maxHeight,
        suggestedWords,
        selectedItems,
        addSelection,
        removeSelection,
        setSelectedItems,
        ...other
      },
      ref,
    ) => {
      const { settings } = useSettings();
      const lang = settings.lang;
      const searchFieldRef = useRef(null);
      const totalRef = useRef(null);
      const searchInputRef = useRef(null);

      const [searchFieldFocus, setSearchFieldFocus] = useState(false);
      const [focusIndex, setFocusIndex] = useState({ category: -2, item: 0 });

      const prevSuggestions = usePrevious(suggestions);
      const prevCategoriesExpanded = usePrevious(categoriesExpanded);

      // useEffect(() => {
      //   if(!expansion && !isEqual(prevSuggestions, suggestions)) {
      //     if(searchMode == 'meta') {
      //       setFocusIndex({category: -2, item: 0});
      //     } else {
      //       setFocusIndex({category: 0, item: 0});
      //     }
      //   } else if(!isEqual(prevCategoriesExpanded, categoriesExpanded)) {
      //     let cat = Object.keys(suggestions)[focusIndex.category]
      //     let count = suggestions[cat].results.length;
      //     if(focusIndex.category >= 0 && focusIndex.item > count-1) {
      //       setFocusIndex({category: focusIndex.category, item: count-1});
      //     }
      //   }
      // })

      useEffect(() => {
        if (ref) {
          if (typeof ref === "function") {
            // handle callback refs
            ref(searchFieldRef.current);
          } else {
            // handle object refs
            ref.current = searchFieldRef.current;
          }
        }
      }, []);

      useEffect(() => {
        if (searchInputRef.current) {
          // searchInputRef.current.focus();
          // setTimeout(() => searchInputRef.current.focus(), 100)
        }
      }, [searchMode]);

      const keyDown = (event) => {
        if (event.key === "Enter") {
          onSelectAll();
        }
      };
      // if(event.key === 'ArrowUp') {
      //   if(focusIndex.category == -2) {
      //     return;
      //   }
      //   if(focusIndex.category == -1) {
      //     if(focusIndex.item == 0) {
      //       setFocusIndex({ category: -2, item: 0 });
      //     } else {
      //       setFocusIndex({ category: -1, item: focusIndex.item-1 });
      //     }
      //     return;
      //   }
      //   if(focusIndex.item == -1) {
      //     if(focusIndex.category == 0) {
      //       if(searchMode == 'meta') {
      //         if(suggestedWords.length > 0) {
      //           setFocusIndex({ category: -1, item: suggestedWords.length-1 });
      //         } else {
      //           setFocusIndex({ category: -2, item: 0 });
      //         }
      //       }
      //     } else {
      //       let newCategory = focusIndex.category-1;
      //       setFocusIndex({ category: newCategory, item: categoryItemShowCount[Object.keys(categoryItemShowCount)[newCategory]]-1 });
      //     }
      //   } else {
      //     setFocusIndex({ category: focusIndex.category, item: focusIndex.item-1 });
      //   }
      // } else if(event.key === 'ArrowDown') {
      //   if(focusIndex.category == -2) {
      //     if(suggestedWords.length > 0) {
      //       setFocusIndex({ category: -1, item: 0 });
      //     } else if(Object.keys(categoryItemShowCount).length > 0) {
      //       setFocusIndex({ category: 0, item: -1 });
      //     }
      //     return;
      //   }
      //   if(focusIndex.category == -1) {
      //     if(focusIndex.item == suggestedWords.length-1) {
      //       if(Object.keys(categoryItemShowCount).length > 0) {
      //         setFocusIndex({ category: 0, item: -1 });
      //       }
      //     } else {
      //       setFocusIndex({ category: -1, item: focusIndex.item+1 });
      //     }
      //     return;
      //   }
      //   if(focusIndex.item == categoryItemShowCount[Object.keys(categoryItemShowCount)[focusIndex.category]]-1) {
      //     if(focusIndex.category < Object.keys(categoryItemShowCount).length-1) {
      //       setFocusIndex({ category: focusIndex.category+1, item: -1 });
      //     }
      //   } else {
      //     setFocusIndex({ category: focusIndex.category, item: focusIndex.item+1 });
      //   }
      // } else if(event.key === 'Enter') {
      //   if(focusIndex.category == -2) {
      //     onSelectAll();
      //   } else if(focusIndex.category == -1) {
      //     onSelectAll(suggestedWords[focusIndex.item].word);
      //   } else {
      //     let category = Object.keys(suggestions)[focusIndex.category];
      //     let suggestion = suggestions[category]
      //     if(focusIndex.item == -1) {
      //       let showCount = categoryItemShowCount[category];
      //       if(showCount !== suggestion.total) {
      //         onExpandCategory(category)
      //       } else {
      //         onReduceCategory(category)
      //       }
      //     } else {
      //       onSelectCategoryItem([suggestion.results[focusIndex.item]]);
      //     }
      //   }
      // }
      // }

      // const keyDown = (event) => {
      //   if(event.key === 'ArrowUp') {
      //     var newFocus = focusIndex - 1
      //     newFocus = newFocus < 0 ? (searchMode === 'key' ? 0 : -1) : newFocus
      //     setFocusIndex(newFocus)
      //   } else if(event.key === 'ArrowDown') {
      //     var newFocus = focusIndex + 1
      //     const maxFocus = suggestedWords.length + reduce(categoryItemShowCount, (sum, x, category) => sum + x + 1, 0) - 1
      //     newFocus = newFocus > maxFocus ? maxFocus : newFocus;
      //     setFocusIndex(newFocus)
      //   } else if(event.key === 'Enter') {
      //     if(focusIndex === -1 && searchMode === 'meta') {
      //       onSelectAll()
      //     } else {
      //       if(focusIndex < suggestedWords.length) {
      //         onSelectAll(suggestedWords[focusIndex].word)
      //         return;
      //       }
      //       var count = suggestedWords.length;
      //       for(let category in categoryItemShowCount) {
      //         let showCount = categoryItemShowCount[category]
      //         let suggestion = suggestions[category]
      //         if(focusIndex <= count + showCount) {
      //           if(focusIndex === count) {
      //             (showCount !== suggestion.total) ? onExpandCategory(category) : onReduceCategory(category)
      //           } else {
      //             onSelectCategoryItem(suggestion.results[focusIndex - count - 1])
      //           }
      //           return;
      //         }
      //         count += showCount + 1
      //       }
      //     }
      //   }
      // }

      return (
        <Main onKeyDown={keyDown} ref={totalRef} {...other}>
          <AA searchFieldFocus={searchFieldFocus}>
            <SearchField
              searchFieldFocus={searchFieldFocus}
              ref={searchFieldRef}
            >
              <SearchInput
                type="text"
                value={searchValue}
                ref={searchInputRef}
                placeholder={ui.labels.searchPlaceholder[lang]}
                onBlur={() => setSearchFieldFocus(false)}
                onFocus={() => setSearchFieldFocus(true)}
                onChange={(event) => onChangeSearchValue(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "ArrowUp") event.preventDefault();
                }}
              />
              {/* {searchMode === "key" &&
                Object.keys(suggestions).length === 0 &&
                resultCount > 1 && (
                  <CommonIconButton
                    tooltip={ui.tooltips.typeDot[lang]}
                    log="searchfield_type_dot"
                    icon={<NavigateNext />}
                    onMouseDown={(event) => {
                      onChangeSearchValue(searchValue + ".");
                      event.preventDefault();
                    }}
                  />
                )} */}
              {/* {searchMode === "meta" && searchValue.length > 2 && (
                <CommonIconButton
                  tooltip={ui.tooltips.applySearch[lang]}
                  log="searchfield_apply"
                  icon={<Done />}
                  onMouseDown={(event) => {
                    onSelectAll(searchValue);
                    event.preventDefault();
                  }}
                />
              )} */}
              <CommonIconButton
                tooltip={ui.tooltips.clearSearch[lang]}
                log="searchfield_clear"
                icon={<Clear />}
                onMouseDown={(event) => {
                  onChangeSearchValue("");
                  event.preventDefault();
                }}
              />
            </SearchField>
            {searchFieldFocus && (
              <StyledSuggestionsView
                maxHeight={maxHeight - 37}
                suggestions={suggestions}
                categoriesExpanded={categoriesExpanded}
                searchTerms={searchTerms}
                searchValue={searchValue}
                onExpandCategory={onExpandCategory}
                onReduceCategory={onReduceCategory}
                searchCategory={searchCategory}
                onRemoveSearchCategory={onRemoveSearchCategory}
                onSelectAll={onSelectAll}
                resultCount={resultCount}
                searchMode={searchMode}
                focusIndex={focusIndex}
                onSelectCategoryItem={onSelectCategoryItem}
                onSelectCategory={onSelectCategory}
                onLoad={onLoad}
                searchHitCount={searchHitCount}
                suggestedWords={suggestedWords}
                onChangeSearchValue={onChangeSearchValue}
                expansion={expansion}
                selectedItems={selectedItems}
                addSelection={addSelection}
                removeSelection={removeSelection}
                setSelectedItems={setSelectedItems}
              />
            )}
          </AA>
        </Main>
      );
    },
  );
