import React, { useState } from "react";
import { ui } from "../constants";
import { Button } from "./Button";
import styled from "@emotion/styled";
import { HowToReg, Person } from "@mui/icons-material";
import { ButtonMenu } from "./ButtonMenu";
import { useUser } from "./UserProvider";
import { useSettings } from "./SettingsProvider";

const SubmitButton = styled(Button)`
  margin: 0 0 0 0px;
  padding: 0.7em 0.8em 0.7em 0.8em;
`;

const VariaButton = styled(Button)`
  color: black;
  background-color: ${ui.stdColor60};
  &:hover {
    background-color: ${ui.stdColor50};
    color: black;
  }
  &:active {
    background-color: ${ui.stdColor100};
    color: white;
  }
  &[aria-pressed="true"] {
    background-color: ${ui.stdColor100};
    color: white;
  }
  height: 100%;
  font-size: 1em;
`;
// const FailLabel = styled.div`
//   background-color: #eb0000;
//   font-weight: 700;
//   color: white;
//   padding: 0.7em 0.8em 0.7em 0.8em;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `

const FailLabel = styled.div`
  /* background-color: #eb0000; */
  font-weight: 700;
  color: red;
  margin-bottom: 15px;
  /* display: flex;
  align-items: center;
  justify-content: center; */
`;

const LoginButton = styled(VariaButton)`
  margin-right: 25px;
  /* border: 1px solid; */
`;

const LoginForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 15px 15px 15px 15px;
  background-color: ${ui.menu.backgroundColor};
`;

const UsernameInput = styled.input`
  transition: border-color 0.2s;
  border: none;
  width: 200px;
  margin: 5px 0 0 0;
  font-size: 1em;
  background-color: white;
  padding: 0.4em 0.5em 0.4em 0.7em;
  border: 1px solid grey;
  :focus {
    border: 1px solid ${ui.stdColor100};
  }
`;

const PasswordInput = styled.input`
  transition: border-color 0.2s;
  border: none;
  width: 200px;
  margin: 15px 0 15px 0;
  font-size: 1em;
  background-color: white;
  padding: 0.4em 0.5em 0.4em 0.7em;
  border: 1px solid grey;
  :focus {
    border: 1px solid ${ui.stdColor100};
  }
`;

export const Login = () => {
  const { login, logout, auth } = useUser();
  const {
    settings: { lang },
  } = useSettings();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [fail, setFail] = useState(false);
  const [show, setShow] = useState(false);

  const loginFetch = () => {
    (async () => {
      try {
        await login(username, password, lang);
        setFail(false);
        setShow(false);
      } catch {
        setFail(true);
      }
    })();
  };

  const button = (
    <LoginButton
      tooltip={ui.tooltips.login[lang]}
      log="login_menu"
      icon={auth.loggedIn ? <HowToReg /> : <Person />}
      iconPosition="left"
      iconChildrenSpace="0.35em"
      ariaPressed={show}
      onMouseDown={() => setShow(true)}
    >
      {auth.loggedIn ? auth.username : ui.labels.login[lang]}
    </LoginButton>
  );

  const logoutForm = (
    <LoginForm
      onSubmit={(event) => {
        logout();
        setShow(false);
        event.preventDefault();
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") logout();
      }}
    >
      <SubmitButton type="submit">Logout</SubmitButton>
    </LoginForm>
  );

  const loginForm = (
    <LoginForm
      onSubmit={(event) => {
        loginFetch(event);
        event.preventDefault();
      }}
      onKeyDown={(event) => {
        if (event.key === "Enter") loginFetch(event);
      }}
    >
      <UsernameInput
        type="text"
        text={username}
        placeholder="Username"
        onChange={(event) => {
          setFail(false);
          setUsername(event.target.value);
        }}
      />
      <PasswordInput
        type="password"
        text={password}
        placeholder="Password"
        onChange={(event) => {
          setFail(false);
          setPassword(event.target.value);
        }}
      />
      {fail && <FailLabel>{ui.labels.loginFailed[lang]}</FailLabel>}
      <SubmitButton type="submit">Login</SubmitButton>
    </LoginForm>
  );

  return (
    <ButtonMenu
      button={button}
      show={show}
      horizontal={true}
      onExit={() => setShow(false)}
    >
      {auth.loggedIn ? logoutForm : loginForm}
    </ButtonMenu>
  );
};
