import { createContext, useContext, useEffect, useState } from "react";

const SettingsContext = createContext({
  settings: {
    showTooltips: true,
    lang: "en",
    multiSelect: false,
    showKeychunks: false,
  },
  changeSetting: () => {},
});

const defaultLang = /^de/.test(navigator.language) ? "de" : "en";
const readSettings = () => {
  const defaultSettings = {
    lang: defaultLang,
    showTooltips: true,
    showKeychunks: false,
    multiSelect: false,
  };
  const settingsText = localStorage.getItem("settings");
  if (!settingsText) {
    return defaultSettings;
  }
  return { ...defaultSettings, ...JSON.parse(settingsText) };
};

export const SettingsProvider = ({ children }) => {
  const [settings, setSettings] = useState(readSettings);
  const changeSetting = (key, value) => {
    if (!Object.keys(settings).includes(key)) {
      throw new Error(`Unknown setting ${key}`);
    }
    setSettings({ ...settings, [key]: value });
  };

  useEffect(() => {
    localStorage.setItem("settings", JSON.stringify(settings));
  }, [settings]);

  return (
    <SettingsContext.Provider value={{ settings, changeSetting }}>
      {children}
    </SettingsContext.Provider>
  );
};

export function useSettings() {
  return useContext(SettingsContext);
}
