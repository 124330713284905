import { createContext, useContext, useEffect, useState } from "react";
import { useSettings } from "./SettingsProvider";
import { useUser } from "./UserProvider";
import { getRange } from "../utils";

const UserDataContext = createContext({
  sets: {},
  collection: [],
  collectionRange: { start: 0, end: 50 },
  collectionKeys: [],
  activeSet: "",
  fetchLoadSets: async () => {
    throw new Error("Not authenticated");
  },
  setFunctions: {
    selectSet() {},
    changeSetName() {},
    removeSet() {},
    removeAll() {},
    addSetToSet() {},
    addToSet() {},
    removeFromSet() {},
    loadCollectionRange() {},
  },
});

export const UserDataProvider = ({ children }) => {
  const { settings } = useSettings();
  const { auth, refreshToken, resetUser } = useUser();

  // Data
  const [sets, setSets] = useState({});
  const [collection, setCollection] = useState([]);
  const [collectionRange, setCollectionRange] = useState({ start: 0, end: 50 });
  const [collectionKeys, setCollectionKeys] = useState([]);
  const [activeSet, setActiveSet] = useState("");

  const setUserData = ({ overview, range, collection, keys, active }) => {
    setSets(overview);
    setCollectionRange(range);
    setCollection(collection);
    setCollectionKeys(keys);
    setActiveSet(active);
  };

  const fetchAuthenticated = async (url, options) => {
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        ...options,
        body: JSON.stringify({
          username: auth.username,
          jwt: auth.jwt,
          lang: settings.lang,
          ...options.body,
        }),
      });

      if (!response.ok) {
        throw new Error("Response was not OK");
      }

      const body = await response.json();
      refreshToken(body.jwt);

      setUserData({
        collection: body.collection,
        overview: body.overview,
        keys: body.keys,
        range: body.range,
        active: body.active,
      });

      return body;
    } catch {
      resetUser();
    }
  };

  const defaultRange = { start: 0, end: 50 };
  const fetchLoadSets = (data = {}) =>
    fetchAuthenticated(`${process.env.REACT_APP_API_URL}/sets/load`, {
      body: { active: activeSet, range: defaultRange, ...data },
    });

  const fetchStoreSet = (data) =>
    fetchAuthenticated(`${process.env.REACT_APP_API_URL}/sets/store`, {
      body: data,
    });

  const fetchStoreSelectSet = (data) =>
    fetchAuthenticated(`${process.env.REACT_APP_API_URL}/sets/storeSelect`, {
      body: data,
    });

  const setFunctions = {
    selectSet(collection) {
      fetchLoadSets({ active: collection });
    },

    changeSetName(from, to) {
      fetchStoreSet({
        type: "rename",
        oldName: from,
        collection: to,
        active: to,
        range: defaultRange,
      });
    },

    removeSet(collection) {
      fetchStoreSet({
        type: "delete",
        collection: collection,
        active: activeSet,
        range: defaultRange,
      });
    },

    removeAll(collection) {
      fetchStoreSet({
        type: "empty",
        collection: collection,
        active: activeSet,
        range: defaultRange,
      });
    },

    addSetToSet(target, source) {
      fetchStoreSet({
        type: "add_keys_from_set",
        collection: target,
        source: source,
        active: activeSet,
        range: defaultRange,
      });
    },

    addToSet(collection, keys, filterIds = undefined, filterTerms = undefined) {
      if (filterIds) {
        fetchStoreSelectSet({
          type: "add_keys",
          filterIds: filterIds,
          filterTerms: filterTerms,
          active: activeSet,
          collection: collection,
          range: defaultRange,
        });
      } else {
        fetchStoreSet({
          type: "add_keys",
          collection: collection,
          keys: keys,
          active: activeSet,
          range: defaultRange,
        });
      }
    },

    removeFromSet(collection, keys) {
      fetchStoreSet({
        type: "delete_keys",
        collection: collection,
        keys: keys,
        active: activeSet,
        range: defaultRange,
      });
    },

    loadCollectionRange(type) {
      const range = getRange(type, collectionRange, 50, collectionKeys.length);
      fetchLoadSets({ range: range });
    },
  };

  useEffect(() => {
    fetchLoadSets({
      range: {
        start: collectionRange.start,
        end: collectionRange.end > 0 ? collectionRange.end : 50,
      },
    });
  }, [
    auth.username,
    settings.lang,
    collectionRange.start,
    collectionRange.end,
  ]);

  return (
    <UserDataContext.Provider
      value={{
        sets,
        collection,
        collectionRange,
        collectionKeys,
        activeSet,
        fetchLoadSets,
        setFunctions,
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserData = () => useContext(UserDataContext);
