import React, { useState, useRef, useEffect, useLayoutEffect } from "react";
import jwt_decode from "jwt-decode";
import { ui } from "../constants";
import { Tooltip } from "./Tooltip";
import styled from "@emotion/styled";
import { ViewTitle } from "./ViewTitle";
import { Button } from "./Button";
import { Publish } from "@mui/icons-material";
import { Notification } from "./Various";
import { useUser } from "./UserProvider";
import { useSettings } from "./SettingsProvider";
import { useUserData } from "./UserDataProvider";

const RotatedPublishIcon = styled(Publish)`
  transform: rotate(180deg);
`;

const ExportTypeButtons = styled.div`
  display: inline-flex;
  margin-top: 7px;
  overflow: hidden;
`;

const DownloadDiv = styled.div`
  display: flex;
  margin-top: 15px;
`;

const DownloadButton = styled(Button)`
  margin-left: 15px;
`;

const DownloadTypeButtons = styled.div`
  display: inline-flex;
  margin-left: 15px;
  overflow: hidden;
`;

const DownloadFormatButtons = styled.div`
  display: inline-flex;
  overflow: hidden;
`;

const ClipboardButton = styled(Button)`
  float: right;
`;

const WarningAPILink = styled.div`
  text-decoration: underline;
  border-bottom: 0px solid;
  font-weight: 700;
  color: black;
  margin: 0 0 0 0px;
  cursor: default;
  padding-left: 0em;
  padding-right: 0em;
  margin-bottom: 0em;
`;

const BottomDiv = styled.div`
  display: flex;
  margin-top: 10px;
  height: 30px;
  align-items: flex-start;
`;

const BottomFlexGrow = styled.div`
  flex-grow: 1;
`;

const ExportTextArea = styled.textarea`
  overflow-y: auto;
  margin-top: 15px;
  height: ${(props) =>
    `calc(100% - 30px - ${props.topHeight}px - ${props.bottomHeight}px)`};
  resize: none;
  outline: none;
  padding: 5px 5px 5px 5px;
  width: 100%;
  border: 0px solid;
  box-shadow: ${ui.boxShadow};
`;

const DataWarning = styled.div`
  margin-top: 5px;
  margin-bottom: 2px;
  font-weight: 400;
`;

export const ExportView = () => {
  const { auth } = useUser();
  const {
    settings: { lang },
  } = useSettings();
  const { collectionKeys } = useUserData();
  const jwt = auth.jwt;
  const [exportType, setExportType] = useState(
    localStorage.getItem("exportType") || "Text",
  );
  const [downloadFormat, setDownloadFormat] = useState(
    localStorage.getItem("downloadFormat") || "CSV",
  );
  const [downloadType, setDownloadType] = useState("Time series");

  const [topHeight, setTopHeight] = useState(0);
  const [bottomHeight, setBottomHeight] = useState(0);
  const [showCopyNotification, setShowCopyNotification] = useState(false);

  const textRef = useRef();
  const topRef = useRef();
  const bottomRef = useRef();

  useEffect(() => {
    localStorage.setItem("exportType", exportType);
  }, [exportType]);

  useEffect(() => {
    localStorage.setItem("downloadFormat", downloadFormat);
  }, [downloadFormat]);

  useEffect(() => {
    if (
      !jwt &&
      (exportType === "R-Code Extern" || exportType === "Download-Link")
    ) {
      setExportType("Text");
    }
  }, [jwt]);

  useLayoutEffect(() => {
    const top = topRef.current;
    if (top && top.clientHeight !== topHeight) {
      setTopHeight(top.clientHeight);
    }
    const bottom = bottomRef.current;
    if (bottom && bottom.clientHeight !== bottomHeight) {
      setBottomHeight(bottom.clientHeight);
    }
  });

  const copyToClipboard = (event) => {
    setShowCopyNotification(true);
    setTimeout(() => setShowCopyNotification(false), 500);
    textRef.current.select();
    document.execCommand("copy");
    event.target.focus();
  };

  const apikey = jwt ? jwt_decode(jwt).iss : "";

  if (exportType === "R-Vector") {
    var keyText = `keys <- c(\n${collectionKeys
      .map((x) => `"${x}"`)
      .join(",\n")})`;
  } else if (exportType === "R-Code KOF") {
    var keyText = `keys <- c(\n${collectionKeys
      .map((x) => `"${x}"`)
      .join(
        ",\n",
      )})\ncon <- kofutils::kof_dbconnect()\ntsl <- timeseriesdb::db_ts_read(con, keys)\nmd <- timeseriesdb::db_metadata_read(con, keys, locale = "${lang}")`;
  } else if (exportType === "R-Code Extern") {
    var keyText = `keys <- c(\n${collectionKeys
      .map((x) => `"${x}"`)
      .join(
        ",\n",
      )})\ntsl <- kofdata::get_time_series(keys, api_key = "${apikey}")\nmd <- kofdata::get_metadata(con, keys, locale = "${lang}")`;
  } else if (exportType === "Text") {
    var keyText = collectionKeys.join("\n");
  } else if (exportType === "Download-Link") {
    var keyText = `https://datenservice.kof.ethz.ch/api/v1/main/${
      downloadType === "Time series" ? "ts" : "metadata"
    }?mime=${downloadFormat.toLowerCase()}&keys=${collectionKeys.join()}&apikey=${apikey}`;
  }

  const isLoggedOut = !auth.loggedIn;

  return (
    <div>
      <div ref={topRef}>
        <ViewTitle label="Export" />
        {(isLoggedOut || !apikey) && (
          <DataWarning>
            <i>
              {isLoggedOut
                ? ui.labels.exportWarnLogin[lang]
                : ui.labels.exportWarnPermission[lang]}
            </i>
          </DataWarning>
        )}
        <ExportTypeButtons>
          <Button
            tooltip={ui.tooltips.plainText[lang]}
            ariaPressed={exportType === "Text"}
            onMouseDown={() => setExportType("Text")}
          >
            Text
          </Button>
          <Button
            tooltip={ui.tooltips.RVector[lang]}
            ariaPressed={exportType === "R-Vector"}
            onMouseDown={() => setExportType("R-Vector")}
          >
            R-Vector
          </Button>
          <Button
            tooltip={ui.tooltips.RCodeKOF[lang]}
            ariaPressed={exportType === "R-Code KOF"}
            onMouseDown={() => setExportType("R-Code KOF")}
          >
            R-Code KOF
          </Button>
          {apikey && (
            <>
              <Button
                tooltip={ui.tooltips.RCodeExtern[lang]}
                ariaPressed={exportType === "R-Code Extern"}
                onMouseDown={() => setExportType("R-Code Extern")}
              >
                R-Code Extern
              </Button>
              <Button
                tooltip={ui.tooltips.downloadLink[lang]}
                ariaPressed={exportType === "Download-Link"}
                onMouseDown={() => setExportType("Download-Link")}
              >
                Download-Link
              </Button>
            </>
          )}
        </ExportTypeButtons>
        {exportType === "Download-Link" && (
          <DownloadDiv>
            <DownloadFormatButtons>
              <Button
                tooltip={ui.tooltips.downloadCSV[lang]}
                ariaPressed={downloadFormat === "CSV"}
                onMouseDown={() => setDownloadFormat("CSV")}
              >
                CSV
              </Button>
              <Button
                tooltip={ui.tooltips.downloadXLSX[lang]}
                ariaPressed={downloadFormat === "XLSX"}
                onMouseDown={() => setDownloadFormat("XLSX")}
              >
                XLSX
              </Button>
              <Button
                tooltip={ui.tooltips.downloadJSON[lang]}
                ariaPressed={downloadFormat === "JSON"}
                onMouseDown={() => setDownloadFormat("JSON")}
              >
                JSON
              </Button>
            </DownloadFormatButtons>
            <DownloadTypeButtons>
              <Button
                tooltip={ui.tooltips.downloadTS[lang]}
                ariaPressed={downloadType === "Time series"}
                onMouseDown={() => setDownloadType("Time series")}
              >
                {ui.labels.exportTS[lang]}
              </Button>
              <Button
                tooltip={ui.tooltips.downloadMeta[lang]}
                ariaPressed={downloadType === "Metadata"}
                onMouseDown={() => setDownloadType("Metadata")}
              >
                {ui.labels.exportMeta[lang]}
              </Button>
            </DownloadTypeButtons>
            <DownloadButton
              tooltip={ui.tooltips.download[lang]}
              icon={<RotatedPublishIcon />}
              log="download"
              iconPosition="left"
              onMouseDown={() => {
                if (downloadFormat === "JSON") window.open(keyText);
                else window.location.href = keyText;
              }}
            >
              Download
            </DownloadButton>
          </DownloadDiv>
        )}
      </div>
      <ExportTextArea
        //rows={keyText.split(/\r\n|\r|\n/).length}
        topHeight={topHeight}
        bottomHeight={bottomHeight}
        value={keyText}
        readOnly
        ref={textRef}
      />
      <BottomDiv ref={bottomRef}>
        {exportType === "Download-Link" && (
          <Tooltip text={ui.tooltips.linkWarning[lang]} delay={200}>
            <WarningAPILink>{ui.labels.warningAPILink[lang]}</WarningAPILink>
          </Tooltip>
        )}
        <BottomFlexGrow />
        <ClipboardButton
          tooltip={ui.tooltips.copyToClipboard[lang]}
          onMouseDown={(event) => copyToClipboard(event)}
        >
          {ui.labels.copyToClipboard[lang]}
        </ClipboardButton>
        <Notification show={showCopyNotification}>Copied!</Notification>
      </BottomDiv>
    </div>
  );
};
