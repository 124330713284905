import React, { useEffect } from "react";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ImportStatus } from "./components/ImportStatus";
import { MainView } from "./components/MainView";

import "./index.css";
import { UserProvider, useUser } from "./components/UserProvider";
import { SettingsProvider } from "./components/SettingsProvider";
import { createRoot } from "react-dom/client";
import { MainState } from "./components/MainState";
import { getUserPath } from "./utils";

const CheckAuth = () => {
  const { auth } = useUser();
  const location = useLocation();

  const url = getUserPath(auth);

  const navigate = useNavigate();
  useEffect(() => {
    const url = getUserPath(auth);
    if (!location.pathname.startsWith(url)) {
      navigate(url);
    }
  }, [auth.username, location, auth.loggedIn]);

  if (!location.pathname.startsWith(url)) {
    return <Navigate to={url} />;
  }

  return <MainView />;
};

const SearchView = ({ mode, sets = false }) => {
  return <MainState searchMode={mode} showSetsExport={sets} />;
};
const defaultChildren = [
  {
    index: true,
    element: <SearchView mode="meta" />,
  },
  {
    path: "keys",
    element: <SearchView mode="key" />,
  },
  {
    path: "collections",
    element: <SearchView sets={true} />,
  },
];

/**
 * @type {import("react-router-dom").RouteObject}
 */
const importRoute = {
  path: "import",
  Component: ImportStatus,
};
const router = createBrowserRouter([
  {
    path: "/",
    element: <CheckAuth />,
    children: [
      {
        path: "guest/:user",
        children: [...defaultChildren],
      },
      {
        path: "user/:user",
        children: [...defaultChildren, importRoute],
      },
    ],
  },
]);
const root = createRoot(document.getElementById("root"));
root.render(
  <SettingsProvider>
    <UserProvider>
      <RouterProvider router={router} />
    </UserProvider>
  </SettingsProvider>,
);
