import { v4 as uuidv4 } from "uuid";
import { createContext, useContext, useState } from "react";
import jwtDecode from "jwt-decode";
import { UserDataProvider } from "./UserDataProvider";

const UserContext = createContext({
  auth: { username: "", jwt: "", loggedIn: false },
  login: async () => {
    throw new Error("Not configured");
  },
  logout: () => {},
  refreshToken: () => {},
  resetUser: () => {},
});

export const UserProvider = ({ children }) => {
  const getGuestUser = () => {
    let guest = localStorage.getItem("guest");
    if (!guest) {
      guest = uuidv4();
      localStorage.setItem("guest", guest);
    }
    return guest;
  };

  const isJWTValid = (jwt) => {
    if (!jwt) {
      return false;
    }
    return jwtDecode(jwt).exp > Date.now() / 1000;
  };
  const [auth, setAuth] = useState(() => {
    const jwt = localStorage.getItem("jwt");
    const username = localStorage.getItem("username");
    if (username && isJWTValid(jwt)) {
      return { jwt, username, loggedIn: true };
    }
    return {
      jwt: "",
      username: getGuestUser(),
      loggedIn: false,
    };
  });
  const data = {};

  const resetUser = () => {
    localStorage.removeItem("jwt");
    localStorage.removeItem("username");
    setAuth({ jwt: "", username: getGuestUser(), loggedIn: false });
  };

  const refreshToken = (newJwt) => {
    if (!newJwt) {
      return resetUser();
    }
    localStorage.setItem("jwt", newJwt);
    setAuth({ ...auth, jwt: newJwt });
  };

  const login = async (username, password, lang) => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/login`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: username,
        password: password,
        range: { start: 0, end: 50 },
        lang: lang,
      }),
    });
    if (!response.ok) {
      throw new Error("Response was not OK");
    }
    const json = await response.json();
    localStorage.setItem("jwt", json.jwt);
    localStorage.setItem("username", username);
    setAuth({ username: username, jwt: json.jwt, loggedIn: true });
    // TODO: set data too
  };

  return (
    <UserContext.Provider
      value={{ auth, data, login, logout: resetUser, refreshToken, resetUser }}
    >
      <UserDataProvider>{children}</UserDataProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
