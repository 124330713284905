import React, { useState, useRef } from "react";
import { Button } from "./Button";
import { SetNameDialog } from "./SetNameDialog";
import { ButtonMenu } from "./ButtonMenu";
import { map, reduce, toInteger } from "lodash";
import { ui } from "../constants";
import styled from "@emotion/styled";
import { PlaylistAdd } from "@mui/icons-material";
import { MenuItemButton, CommonIconButton } from "./Various";
import { copyToClipboard } from "../utils";
import { useUserData } from "./UserDataProvider";
import { useSettings } from "./SettingsProvider";

const TargetSetLabel = styled.span`
  font-weight: 700;
  font-style: italic;
`;

const TooManyWarning = styled.span`
  font-style: italic;
  display: inline;
  color: red;
  margin-left: 1em;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
`;

const Icon = styled(PlaylistAdd)`
  margin: -3px;
`;

export const ResultsButtonMenu = ({
  series,
  seriesCount,
  filterTerms,
  filterIds,
}) => {
  const {
    settings: { lang },
  } = useSettings();
  const { sets, setFunctions: setFunc } = useUserData();
  const [show, setShow] = useState(false);
  const [dialog, setDialog] = useState(null);

  const targetSetNames = Object.keys(sets);
  const items = map(targetSetNames, (targetSetName) => {
    const tooMany = seriesCount + toInteger(sets[targetSetName]) > 10000;
    // const targetSet = sets[targetSetName]
    // const targetSetKeys = targetSet.map(e => e.key)
    // const allInSet = reduce(keys, (found, key) => found && targetSetKeys.includes(key), true)
    return (
      <MenuItemButton
        key={targetSetName}
        disabled={tooMany}
        onMouseDown={(event) => {
          setFunc.addToSet(
            targetSetName,
            null,
            Object.values(filterIds),
            filterTerms,
          );
          setShow(false);
          event.stopPropagation();
        }}
      >
        <div>
          <span>{ui.labels.addAllToSet[lang]}</span>
          <TargetSetLabel>{targetSetName}</TargetSetLabel>
          {tooMany && (
            <TooManyWarning>{ui.labels.tooManyWarning[lang]}</TooManyWarning>
          )}
        </div>
      </MenuItemButton>
    );
  });

  const tooMany = seriesCount > 10000;
  const buttonNewSet = (
    <MenuItemButton
      dividerTop
      disabled={tooMany}
      onMouseDown={(event) => {
        setDialog("");
        setShow(false);
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <div>
        <span>{ui.labels.addAllToNewSet[lang]}</span>
        {tooMany && (
          <TooManyWarning>{ui.labels.tooManyWarning[lang]}</TooManyWarning>
        )}
      </div>
    </MenuItemButton>
  );

  // const buttonCopyKeys =
  //   <MenuItemButton
  //     divider
  //     onMouseDown={event => { copyToClipboard(keys.join('\n'));
  //                             notify(ui.labels.notifCopied[lang]);
  //                             setShow(false);
  //                             event.stopPropagation(); }}>
  //     {ui.labels.copyKeysToClipboard[lang]}
  //   </MenuItemButton>

  const button = (
    <CommonIconButton
      log="results_options_menu"
      tooltip={ui.tooltips.resultsOptions[lang]}
      ariaPressed={show}
      icon={<Icon />}
      onMouseDown={(event) => {
        setShow(true);
        event.stopPropagation();
      }}
    />
  );

  return (
    <>
      <ButtonMenu button={button} show={show} onExit={() => setShow(false)}>
        <MenuItems>
          {items}
          {buttonNewSet}
          {/* {buttonCopyKeys} */}
        </MenuItems>
      </ButtonMenu>
      <SetNameDialog
        show={dialog !== null}
        value={dialog}
        alreadyExists={Object.keys(sets).includes(dialog)}
        onCancel={() => setDialog(null)}
        onSave={() => {
          setFunc.addToSet(dialog, null, Object.values(filterIds), filterTerms);
          setDialog(null);
        }}
        onChange={(value) => setDialog(value)}
      />
    </>
  );
};
