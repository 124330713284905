import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material";
import { MainMenu } from "./MainMenu";

const mainTheme = createTheme();

const MainContent = styled.section`
  width: 100%;
  padding: 15px 25px 25px 25px;
  display: flex;
  height: calc(100vh - 40px);
`;

const Viewport = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
`;

export const MainView = () => {
  // const fetchExtend = () => {
  //   fetch(`${process.env.REACT_APP_API_URL}/ts/extend`, {
  //     method: 'POST',
  //     body: JSON.stringify({ jwt: loginState.jwt }),
  //     headers: {'Content-Type': 'application/json'}
  //   })
  //   .then(res => res.json())
  //   .then(json => {
  //     setLogin({jwt: json.jwt, username: username});
  //     localStorage.setItem('jwt', jwt);
  //     setTimeout(fetchExtend, 1000*60)
  //   })
  //   .catch(error => {
  //     login(uuidv4(), null, {}, [], null);
  //   })
  // }

  return (
    <ThemeProvider theme={mainTheme}>
      <Viewport>
        <MainMenu />
        <MainContent>
          <Outlet />
        </MainContent>
      </Viewport>
    </ThemeProvider>
  );
};
