import React, { useState } from 'react';
import { ui } from '../constants';
import { darkenColor } from '../utils'
import styled from '@emotion/styled';

const StyledItem = styled.div`
  transition: 'background-color 0.15s';
  user-select: 'text';
  border-bottom: 1px solid ${ui.dividerColor};
  &:last-child {
    border-bottom: none;
  }
  background-color: ${props => props.backgroundColor};
`

const SelectionBar = styled.div`
  transition: border-left-color 0.15s;
  display: flex;
  border-left: 3px solid;
  border-left-color: ${props => props.barBackgroundColor};
`

export const ListItem = ({
  itemKey,
  content,
  selected,
  expanded,
  selectionColor,
  onChangeHover,
  onMouseDown,
  contentRenderer,
  toggleExpansion}) => 
{
  const [hover, setHover] = useState(false)

  const mouseEnter = () => {
    setHover(true)
    if(onChangeHover !== undefined) {
      onChangeHover(itemKey, true)
    }
  }

  const mouseLeave = () => {
    setHover(false)
    if(onChangeHover !== undefined) {
      onChangeHover(itemKey, false)
    }
  }

  var backgroundColor = hover ? darkenColor('white', 0.025) : 'white'
 
  return(
    <StyledItem
      backgroundColor={backgroundColor}
      onMouseDown={onMouseDown}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}>
        <SelectionBar barBackgroundColor={selected ? selectionColor : backgroundColor}>
          {contentRenderer(itemKey, content, selected, expanded, hover, toggleExpansion)}
        </SelectionBar>
    </StyledItem>
  )
}