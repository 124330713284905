import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { ListItem } from "./ListItem";

const Main = styled.div`
  cursor: default;
`;

export const List = ({
  itemKeys = [],
  itemContents = [],
  selectedItems = [],
  onChangeSelection,
  itemContentRenderer,
  onChangeHover,
  selectionColors = [],
  dividerColor,
  other,
}) => {
  const [expandedItems, setExpandedItems] = useState([]);

  useEffect(() => {
    const newExpanded = expandedItems.filter((key) => itemKeys.includes(key));
    setExpandedItems(newExpanded);
  }, [itemKeys]);

  const itemClicked = (event, key) => {
    var newSelected;
    if (event.ctrlKey || event.metaKey) {
      newSelected = [...selectedItems];
      var index = newSelected.indexOf(key);
      if (index !== -1) {
        newSelected.splice(index, 1);
      } else {
        newSelected.push(key);
      }
    } else {
      newSelected = [key];
    }
    onChangeSelection(newSelected);
  };

  const toggleItemExpansion = (key) => {
    var newExpanded = [...expandedItems];
    var index = newExpanded.indexOf(key);
    if (index !== -1) {
      newExpanded.splice(index, 1);
    } else {
      newExpanded.push(key);
    }
    setExpandedItems(newExpanded);
  };

  const items = itemContents.map((itemContent, i) => {
    var key = itemKeys[i];
    var selectionIndex = selectedItems.indexOf(key);
    var selected = selectionIndex !== -1;
    var color = selected ? selectionColors[selectionIndex] : "black";
    return (
      <ListItem
        key={key}
        itemKey={key}
        content={itemContent}
        selected={selected}
        expanded={expandedItems.indexOf(key) !== -1}
        selectionColor={color}
        dividerColor={i === itemContents.length - 1 ? undefined : dividerColor}
        onMouseDown={
          onChangeSelection === undefined
            ? undefined
            : (event) => itemClicked(event, key)
        }
        toggleExpansion={toggleItemExpansion}
        contentRenderer={itemContentRenderer}
        onChangeHover={onChangeHover}
      />
    );
  });

  return <Main {...other}>{items}</Main>;
};
