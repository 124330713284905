import Color from "color";
import { range } from "lodash";

export function markThousands(val) {
  return val < 1000
    ? val.toString()
    : val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "'");
}

export function upperCaseFirst(value) {
  if (value.startsWith("http")) {
    return value;
  } else {
    return value.charAt(0).toUpperCase() + value.substr(1, value.length - 1);
  }
}

export function darkenColor(color, val) {
  var rgbColor = Color(color).rgb();
  for (let i = 0; i < 3; i++) {
    rgbColor.color[i] -= val * rgbColor.color[i];
  }
  return rgbColor.hex();
}

export function lightenColor(color, val) {
  var rgbColor = Color(color).rgb();
  for (let i = 0; i < 3; i++) {
    rgbColor.color[i] += val * (255 - rgbColor.color[i]);
  }
  return rgbColor.hex();
}

export function checkMissing(value, lang) {
  if (lang == "de") {
    return value === "Metadaten fehlen";
  } else {
    return value === "metadata missing";
  }
}

export function copyToClipboard(text) {
  console.log("text", text);
  var textField = document.createElement("textarea");
  textField.value = text;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand("copy");
  textField.remove();
}

export function getRange(type, current, count, max) {
  if (type == "start") {
    var range = { start: 0, end: count };
  } else if (type == "end") {
    var range = { start: max - count, end: max };
  } else if (type == "next") {
    var range = { start: current.start + 50, end: current.end + 50 };
    if (range.end > max) {
      range = { start: max - 50, end: max };
    }
  } else if (type == "last") {
    var range = { start: current.start - 50, end: current.end - 50 };
    if (range.start < 0) {
      range = { start: 0, end: count };
    }
  }
  return range;
}

export function getUserPath(auth) {
  const userType = auth.loggedIn ? "user" : "guest";
  return `/${userType}/${auth.username}`;
}
