import React, { useState, useRef, memo, useEffect } from "react";
import { List } from "./List";
import { ViewTitle } from "./ViewTitle";
import { difference, isEqual, cloneDeep } from "lodash";
import { ScrollBox } from "./ScrollBox";
import { PlotView } from "./PlotView";
import { ui, colors } from "../constants";
import { createTSContentRenderer } from "./createTSContentRenderer";
import { createPlotTSContentRenderer } from "./createPlotTSContentRenderer";
import {
  NavigateNext,
  NavigateBefore,
  LastPage,
  FirstPage,
} from "@mui/icons-material";
import styled from "@emotion/styled";
import { Button } from "./Button";
import { useSettings } from "./SettingsProvider";

const TSListView = styled.div`
  display: grid;
  grid-template-rows: auto auto 1fr;
  width: 45%;
  padding: 0 20px 0 0;
`;

const Main = styled.div`
  display: flex;
`;

const TSListScrollBox = styled(ScrollBox)`
  margin-top: 7px;
  box-shadow: ${ui.boxShadow};
`;

const StyledPlotView = styled(PlotView)`
  width: 55%;
  padding: 0 0 0 20px;
`;

const NavigationButtons = styled.div`
  display: flex;
  align-items: stretch;
`;

const NavigateButton = styled(Button)`
  width: 70px;
`;

const PageNumbers = styled.div`
  flex-grow: 1;
  background-color: ${ui.stdColor20};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 1.1em;
`;

const defaultTSTransform = {
  limit: false,
  fit: false,
  diff: false,
  log: false,
};

export const SeriesView = memo(
  ({
    series,
    title,
    seriesCount,
    range,
    loadRange,
    highlightTerms = [],
    resetScrollOnChange = false,
    optionsButtonRenderer,
    seriesOptionsButtonRenderer,
    ...other
  }) => {
    const { settings } = useSettings();
    const lang = settings.lang;
    const [selectedSeries, setSelectedSeries] = useState([]);

    const titleRef = useRef();
    const leftRef = useRef();
    const scrollRef = useRef();

    var newSelected = selectedSeries.filter((x) =>
      series.some((y) => x.key === y.key),
    );

    useEffect(() => {
      if (resetScrollOnChange && scrollRef.current) {
        scrollRef.current.scrollTop = 0;
      }
    }, [series]);

    const toggleTransform = (key, transform) => {
      var newSelected = cloneDeep(selectedSeries);
      var sel = newSelected.find((e) => e.key === key);
      var val = sel.transform[transform];
      if (transform === "limit" || transform === "fit") {
        newSelected.map((x) => (x.transform[transform] = false));
      }
      sel.transform[transform] = !val;
      setSelectedSeries(newSelected);
    };

    const unselectSeries = (key) => {
      var newSelected = cloneDeep(selectedSeries);
      newSelected = newSelected.filter((x) => x.key !== key);
      if (newSelected.length == 0 && series.length != 0) {
        newSelected = [
          {
            key: series[0].key,
            color: colors.plot[0],
            transform: { ...defaultTSTransform },
          },
        ];
      }
      setSelectedSeries(newSelected);
    };

    const selectSeries = (key) => {
      changeSelection([...selectedSeries.map((x) => x.key), key]);
    };

    const changeSelection = (keys) => {
      var newSelected = cloneDeep(selectedSeries);
      newSelected = newSelected.filter((x) => keys.includes(x.key));
      var newKeys = keys.filter(
        (key) => !newSelected.some((x) => x.key === key),
      );
      var availableColors = difference(
        colors.plot,
        newSelected.map((x) => x.color),
      );
      var newSelected = [
        ...newSelected,
        ...newKeys.map((key, i) => ({
          key: key,
          color: availableColors[i],
          transform: { ...defaultTSTransform },
        })),
      ];
      setSelectedSeries(newSelected);
    };

    if (newSelected.length == 0 && series.length != 0) {
      newSelected = [
        {
          key: series[0].key,
          color: colors.plot[0],
          transform: { ...defaultTSTransform },
        },
      ];
      setSelectedSeries(newSelected);
    }

    if (newSelected.length != selectedSeries.length) {
      newSelected = cloneDeep(newSelected);
      setSelectedSeries(newSelected);
    }

    const tsContentRenderer = createTSContentRenderer(
      highlightTerms,
      lang,
      settings.showKeychunks,
      unselectSeries,
      selectSeries,
      optionsButtonRenderer,
    );
    const plotTSContentRenderer = createPlotTSContentRenderer(
      unselectSeries,
      lang,
      settings.showKeychunks,
      toggleTransform,
    );

    const selections = selectedSeries.map((x) => ({
      ...series.find((y) => x.key === y.key),
      transform: x.transform,
    }));
    const selectionColors = selectedSeries.map((x) => x.color);
    const selectionKeys = selections.map((x) => x.key);

    return (
      <Main {...other}>
        <TSListView ref={leftRef}>
          <ViewTitle label={title} count={seriesCount} ref={titleRef}>
            {seriesOptionsButtonRenderer && seriesOptionsButtonRenderer(series)}
          </ViewTitle>
          {series.length != 0 && (
            <TSListScrollBox scrollRef={scrollRef}>
              <List
                itemKeys={series.map((e) => e.key)}
                itemContents={series}
                selectedItems={selectionKeys}
                onChangeSelection={changeSelection}
                selectionColors={selectionColors}
                dividerColor={ui.dividerColor}
                itemContentRenderer={tsContentRenderer}
              />
            </TSListScrollBox>
          )}
          {seriesCount > 50 && (
            <NavigationButtons>
              <NavigateButton
                disabled={range.start === 0}
                icon={<FirstPage />}
                onMouseDown={(event) => loadRange("start")}
              />
              <NavigateButton
                disabled={range.start === 0}
                icon={<NavigateBefore />}
                onMouseDown={(event) => loadRange("last")}
              />
              <PageNumbers>{range.start + 1 + "-" + range.end}</PageNumbers>
              <NavigateButton
                disabled={range.end >= seriesCount}
                icon={<NavigateNext />}
                onMouseDown={(event) => loadRange("next")}
              />
              <NavigateButton
                disabled={range.end >= seriesCount}
                icon={<LastPage />}
                onMouseDown={(event) => loadRange("end")}
              />
            </NavigationButtons>
          )}
        </TSListView>
        <StyledPlotView
          lang={lang}
          selectionKeys={selectionKeys}
          selections={selections}
          selectionColors={selectionColors}
          tsContentRenderer={plotTSContentRenderer}
        />
      </Main>
    );
  },
  (prevProps, nextProps) => {
    return (
      isEqual(nextProps.series, prevProps.series) &&
      isEqual(nextProps.highlightTerms, prevProps.highlightTerms) &&
      isEqual(nextProps.title, prevProps.title)
    );
  },
);
