import React, { useRef } from "react";
import { SeriesView } from "./SeriesView";
import { ui } from "../constants";
import { SetsView } from "./SetsView";
import { ExportView } from "./ExportView";
import { isEqual } from "lodash";
import styled from "@emotion/styled";
import { SetsTSButtonMenu } from "./SetsTSButtonMenu";
import { ResultsButtonMenu } from "./ResultsButtonMenu";
import { useSettings } from "./SettingsProvider";
import { useUserData } from "./UserDataProvider";

const SeriesViewDiv = styled.div`
  width: 70%;
  height: 100%;
  padding: 0 0 0 20px;
`;

const StyledExportView = styled(ExportView)`
  margin-top: 15px;
  height: 50%;
`;

const StyledSetsView = styled(SetsView)`
  height: 50%;
`;

const StyledSeriesView = styled(SeriesView)`
  width: 70%;
  height: 100%;
  padding: 0 0 0 20px;
`;

const SetsExportFlex = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  width: 30%;
  height: 100%;
  padding: 0 20px 0 0;
`;

export const SetsExportView = React.memo(
  ({ onSetFilter }) => {
    const {
      settings: { lang },
    } = useSettings();
    const {
      activeSet,
      collection,
      collectionKeys,
      collectionRange,
      setFunctions,
    } = useUserData();
    const prevActiveSet = useRef(null);

    var resetScrollOnChange = false;
    if (activeSet !== prevActiveSet.current) {
      prevActiveSet.current = activeSet;
      resetScrollOnChange = true;
    }

    // const seriesOptionsButtonRenderer = (series, sets) =>
    // <ResultsButtonMenu
    //   sets={sets}
    //   series={series}
    //   onAddSet={onAddSet}
    //   onAddToSet={onAddToSet}
    //   onRemoveFromSet={onRemoveFromSet}
    //   lang={lang}/>

    const optionsButtonRenderer = (
      content,
      hover,
      selectSeries,
      unselectSeries,
      selected,
    ) => (
      <SetsTSButtonMenu
        content={content}
        hover={hover}
        unselectSeries={unselectSeries}
        selectSeries={selectSeries}
        selected={selected}
        onSetFilter={onSetFilter}
      />
    );

    const title = (
      <span style={{ fontWeight: 700 }}>
        {ui.labels.resultsTitle[lang] + " in "}
        <i>{activeSet}</i>
      </span>
    );

    return (
      <>
        <SetsExportFlex>
          <StyledSetsView />
          <StyledExportView />
        </SetsExportFlex>
        {activeSet && (
          <StyledSeriesView
            series={collection}
            seriesCount={collectionKeys.length}
            range={collectionRange}
            loadRange={setFunctions.loadCollectionRange}
            title={title}
            resetScrollOnChange={resetScrollOnChange}
            optionsButtonRenderer={optionsButtonRenderer}
          />
        )}
      </>
    );
  },
  (prevProps, nextProps) => {
    return isEqual(prevProps.style, nextProps.style);
  },
);
