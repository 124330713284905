import React, { forwardRef } from 'react'
import { ui } from '../constants'
import { markThousands } from '../utils'
import { WarningIcon } from './Various'
import styled from "@emotion/styled";

const TitleCount = styled.span`
  padding: 0.5em 0.0em 0.4em 0.0em;
  margin-left: 0.4em;
  font-size: 1.15em;
`

const TitleLabel = styled.div`
  padding: 0.5em 0.0em 0.4em 0.0em;
  font-weight: 700;
  font-size: 1.15em;
`

const TitleMain = styled.div`
  display: flex;
  align-items: center;
  /* border-bottom: ${ui.titleBorderBottom}; */
`

const FlexGrow = styled.div`
  flex-grow: 1;
`

const StyledWarningIcon = styled(WarningIcon)`
`

export const ViewTitle = forwardRef(({label, count, warning, children}, ref) =>  
  <TitleMain ref={ref}>
    <TitleLabel>{label}</TitleLabel>
    {count !== undefined && <TitleCount>{'(' + markThousands(count) + ')'}</TitleCount>}
    <FlexGrow/>
    {warning && <StyledWarningIcon tooltip={warning}/>}
    {children}
  </TitleMain>)